import { useState, useRef, useEffect } from "react";
import { compactCell, getColor, boldTarget } from "./style";
import { usd, usdCompact, pe, usdChange, percentChange } from "./formater";
import { columns } from "./columns";
import { AlertRowIcon, AddIcon, ClearIcon, CloseIcon } from "./icons";
import Modal from "react-modal";
import styles from "./app.module.css";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light-border.css";
import { updateNote } from "./userData";

//prettier-ignore
export function Cells(props) {
  return (
    <>
      <SymbolCell
        symbol={props.symbol}
        toggleLogo={props.toggleLogo}
        type={props.type}
        changeShowDetails={props.changeShowDetails}
        changeDetailSymbol={props.changeDetailSymbol}
      />
      <NameCell name={props.name} isCompact={props.toggleCompact} type={props.type} toggleLogo={props.toggleLogo} symbol={props.symbol} />
      <NumberCell val={props.price} formatter={usd} hidden={columns[2].hide} />
      <NumberCell val={props.marketCap} formatter={usdCompact} hidden={columns[3].hide} />
      <NumberCell val={props.pe} formatter={pe} hidden={columns[4].hide} />
      <NumberCell val={props.volume} hidden={columns[5].hide} formatter={usdCompact} />
      <PriceCell val={props.change} formatter={usdChange} hidden={columns[6].hide} highlight={props.showHighlight} percent={props.percent} />
      <PercentCell val={props.percent} formatter={percentChange} hidden={columns[7].hide} highlight={props.showHighlight} />

    </>
  );
}

//Different cells /collumns are rendered for a watchlist versus the screener.
export function WatchlistCells(props) {
  const notify = props.alerts.includes(props.symbol);
  return (
    <>
      <SymbolCell
        symbol={props.symbol}
        toggleLogo={props.toggleLogo}
        notify={notify}
        changeShowDetails={props.changeShowDetails}
        changeDetailSymbol={props.changeDetailSymbol}
      />
      <NameCell
        name={props.name}
        type={props.type}
        isCompact={props.toggleCompact}
        toggleLogo={props.toggleLogo}
        symbol={props.symbol}
        notify={props.alerts.includes(props.symbol)}
      />
      <NumberCell val={props.price} formatter={usd} notify={notify} />
      <PriceCell
        val={props.change}
        formatter={usdChange}
        highlight={props.showHighlight}
        percent={props.percent}
      />
      <PercentCell val={props.percent} formatter={percentChange} highlight={props.showHighlight} />
      <BuyCell
        target={props.targets[props.symbol]}
        symbol={props.symbol}
        newBuyTarget={props.newBuyTarget}
        formatter={usd}
        notify={notify}
        clearBuyTarget={props.clearBuyTarget}
        price={props.price}
      />
      <SellCell
        target={props.targets[props.symbol]}
        symbol={props.symbol}
        newSellTarget={props.newSellTarget}
        clearSellTarget={props.clearSellTarget}
        formatter={usd}
        notify={notify}
        price={props.price}
      />
      <NoteCell
        note={props.note}
        symbol={props.symbol}
        addNote={props.addNote}
        removeNote={props.removeNote}
      />
    </>
  );
}

function NoteCell({ note, symbol, addNote, removeNote }) {
  const [modalOpen, setModalOpen] = useState(false);
  const textareaRef = useRef(null);

  function NoteModal() {
    const [newPrice, setNewPrice] = useState("");

    const CloseButton = () => {
      return (
        <span onClick={() => setModalOpen(false)}>
          <CloseIcon />
        </span>
      );
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      const note_data = textareaRef.current.value;
      updateNote(symbol, note_data);
      addNote({ [symbol]: note_data });
    };

    return (
      <Modal
        isOpen={modalOpen}
        className="mx-auto mt-[10%] w-1/4 rounded bg-[#3353ac] p-8"
        autoFocus={false}
        ariaHideApp={false}
      >
        <div className=" flex flex-col gap-y-4 ">
          <div className="text-xl font-bold text-white">
            Note for {symbol} <CloseButton />
          </div>
          <form onSubmit={handleSubmit} className="w-full rounded   text-lg">
            <textarea
              autoFocus={modalOpen}
              ref={textareaRef}
              id="textarea"
              name="textarea"
              rows={10}
              maxLength={510}
              defaultValue={note}
              className="box-border w-full resize-none rounded border-black bg-[#f9e168] p-4 font-sans text-xl text-[#1f1f1f] outline-none"
              onKeyDown={(e) => (e.key === "Enter" && !e.shiftKey ? handleSubmit(e) : null)}
              onFocus={(e) =>
                e.currentTarget.setSelectionRange(
                  e.currentTarget.value.length,
                  e.currentTarget.value.length
                )
              }
            ></textarea>
            <div className="mt-4 flex justify-around gap-x-4 ">
              <button
                onClick={() => {
                  removeNote(symbol);
                  setModalOpen(false);
                }}
                className="h-10 cursor-pointer rounded bg-inherit text-lg text-red-500 hover:underline"
                disabled={symbol === ""}
              >
                <b>Delete Note</b>
              </button>
              <button
                type="submit"
                className="h-10 w-1/3 cursor-pointer rounded text-lg hover:bg-blue-300"
                disabled={symbol === ""}
              >
                <b>Update</b>
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }

  return (
    <td onClick={(e) => e.stopPropagation()}>
      <div>
        <Tippy
          content={note ? note : "Add Note"}
          placement={note ? "top-end" : "left"}
          delay="100"
          theme={note ? "notes" : ""}
          arrow={note ? false : true}
        >
          <button
            className=" mx-auto flex cursor-pointer justify-center rounded  bg-inherit px-1 text-white hover:bg-slate-500"
            onClick={(e) => {
              e.stopPropagation();
              setModalOpen(true);
            }}
          >
            {note ? (
              <img className="w-6 rounded" src="https://www.powerinvest.pro/note.svg" alt="note" />
            ) : (
              <img
                className="w-6 rounded"
                src="https://www.powerinvest.pro/edit2.svg"
                alt="edit note"
              />
            )}
          </button>
        </Tippy>

        <NoteModal />
      </div>
    </td>
  );
}

function SymbolCell({ symbol, toggleLogo, type, changeShowDetails, changeDetailSymbol }) {
  const url =
    type !== "ETF"
      ? `https://www.powerinvest.pro/${symbol}.svg`
      : `https://www.powerinvest.pro/ETF.svg`;
  return (
    <td className="">
      <div className="flex h-full">
        <Tippy content="See More Details" placement="right" delay="200" disabled={!symbol}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (symbol === "") return;
              changeDetailSymbol(symbol);
              changeShowDetails(true);
            }}
            className={`flex h-full w-11/12 cursor-pointer flex-row flex-nowrap items-center justify-around gap-x-2 rounded px-2 py-1 ${
              symbol ? "hover:bg-yellow-500" : ""
            }`}
          >
            {toggleLogo ? <img src={url} className=" h-6 w-6 rounded-sm" alt="" /> : null}

            <div>{symbol.replace("-USD", "")}</div>
          </div>
        </Tippy>
      </div>
    </td>
  );
}

function NameCell({ name, isCompact, notify, type }) {
  const truncate = (str) => {
    if (str.length < 32) return str;
    else {
      const breakChars = [" ", ",", "-"];
      let i = 0;
      while (!breakChars.includes(str[31 + i]) && i + 31 < str.length) i++;
      return str.substring(0, 32 + i);
    }
  };
  return (
    <td style={compactCell(isCompact)}>
      <div className="ml-6 text-left">
        {notify && <AlertRowIcon />}
        <span style={boldTarget(notify)}>
          {name === undefined
            ? "-"
            : truncate(type === "CRYPTOCURRENCY" ? name + ` (Crypto)` : name)}{" "}
        </span>
      </div>
    </td>
  );
}

function NumberCell({ val, formatter, hidden, notify }) {
  return !hidden ? (
    <td className={styles.NumberCell}>
      <span style={boldTarget(notify)}> {val === undefined ? "-" : formatter.format(val)} </span>
    </td>
  ) : null;
}

//pass percent so getColor value is percent change
function PriceCell({ val, formatter, hidden, highlight, percent }) {
  const formattedVal = formatter.format(val);
  return !hidden ? (
    <td className={styles.PriceCell} style={{ ...getColor(percent, highlight) }}>
      {" "}
      {val === undefined ? "-" : formattedVal}{" "}
    </td>
  ) : null;
}

function PercentCell({ val, formatter, hidden, highlight }) {
  const formattedVal = formatter.format(val);
  return !hidden ? (
    <td className={styles.PercentCell} style={{ ...getColor(val, highlight) }}>
      {" "}
      {val === undefined ? "-" : formattedVal}
    </td>
  ) : null;
}

function BuyCell({ target, symbol, newBuyTarget, formatter, notify, clearBuyTarget, price }) {
  const [modalOpen, setModalOpen] = useState(false);

  function PriceModal() {
    const [newPrice, setNewPrice] = useState("");

    const modalStyles = {
      content: {
        top: "25%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "20%",
        backgroundColor: "#3353ac",
        color: "white",
      },
    };
    const CloseButton = () => {
      return (
        <span onClick={() => setModalOpen(false)}>
          <CloseIcon />
        </span>
      );
    };

    return (
      <Modal isOpen={modalOpen} style={modalStyles} autoFocus={false} ariaHideApp={false}>
        <CloseButton />
        <div style={{ height: "80px", marginTop: 30, marginBottom: 15, marginLeft: 20 }}>
          <div style={{ marginBottom: 10, fontSize: 20, fontWeight: 700 }}>
            Set a buy price target for {symbol}:{" "}
          </div>
          <input
            autoFocus={true}
            style={{
              width: "70%",
              marginRight: 6,
              height: "2em",
              fontSize: "0.9em",
              fontWeight: 700,
            }}
            onChange={(e) => setNewPrice(e.target.value)}
            onKeyDown={(e) => (e.key === "Enter" ? newBuyTarget(symbol, newPrice) : null)}
            type="number"
            placeholder={price}
            autoComplete="off"
            max={1000}
            value={newPrice}
            step={0.01}
          />

          <button
            style={{ height: "2em", fontSize: 16 }}
            disabled={symbol === ""}
            onClick={() => newBuyTarget(symbol, newPrice)}
          >
            <b>Submit</b>
          </button>
        </div>
      </Modal>
    );
  }

  return (
    <td
      // style={{ textAlign: "right", paddingRight: 40 }}
      // className={styles.TargetCell}
      className="pr-4"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex items-center justify-end gap-x-0.5 "
      >
        <span style={boldTarget(notify)}>
          {target !== undefined && target["buy"] !== undefined ? formatter.format(target.buy) : ""}{" "}
        </span>
        <div
          className="ml-1.5 flex items-center justify-center"
          onClick={(e) => {
            setModalOpen(symbol !== "");
            e.stopPropagation();
          }}
        >
          <AddIcon size={25} />
        </div>
        <PriceModal />
        <div
          className="flex items-center justify-center"
          onClick={(e) => {
            clearBuyTarget(symbol);
            e.stopPropagation();
          }}
        >
          <ClearIcon />
        </div>
      </div>
    </td>
  );
}

function SellCell({ target, symbol, newSellTarget, clearSellTarget, formatter, notify, price }) {
  const [modalOpen, setModalOpen] = useState(false);

  function PriceModal() {
    const [newPrice, setNewPrice] = useState("");

    const modalStyles = {
      content: {
        top: "25%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "20%",
        backgroundColor: "#3353ac",
        color: "white",
      },
    };
    const CloseButton = () => {
      return (
        <span onClick={() => setModalOpen(false)}>
          <CloseIcon />
        </span>
      );
    };

    return (
      <Modal isOpen={modalOpen} style={modalStyles} autoFocus={false} ariaHideApp={false}>
        <CloseButton />
        <div style={{ height: "80px", marginTop: 30, marginBottom: 15, marginLeft: 20 }}>
          <div style={{ marginBottom: 10, fontSize: 20, fontWeight: 700 }}>
            Set a buy price target for {symbol}:{" "}
          </div>
          <input
            autoFocus={true}
            style={{
              width: "70%",
              marginRight: 6,
              height: "2em",
              fontSize: "0.9em",
              fontWeight: 700,
            }}
            onChange={(e) => setNewPrice(e.target.value)}
            onKeyDown={(e) => (e.key === "Enter" ? newSellTarget(symbol, newPrice) : null)}
            type="number"
            placeholder={price}
            autoComplete="off"
            max={1000}
            value={newPrice}
            step={0.01}
          />
          <button
            style={{ height: "2em", fontSize: 16 }}
            onClick={() => newSellTarget(symbol, newPrice)}
          >
            <b>Submit</b>
          </button>
        </div>
      </Modal>
    );
  }

  return (
    <td
      style={{ textAlign: "right", paddingRight: 10 }}
      className={styles.TargetCell}
      onClick={(e) => e.stopPropagation()}
    >
      <span style={boldTarget(notify)}>
        {target !== undefined && target["sell"] !== undefined ? formatter.format(target.sell) : "-"}{" "}
      </span>
      <span
        onClick={(e) => {
          setModalOpen(symbol !== "");
          e.stopPropagation();
        }}
      >
        <AddIcon />
      </span>
      <PriceModal />
      <span
        className={styles.deleteTarget}
        onClick={(e) => {
          clearSellTarget(symbol);
          e.stopPropagation();
        }}
      >
        <ClearIcon />
      </span>
    </td>
  );
}
