import { useEffect, useState } from "react";
import styles from "./app.module.css";
import { usd, usdChange, usdCompact } from "./formater";
import Graph from "./graph";
import { getPriceData, getDetailPriceData } from "./DataFetcher";

function Details({ symbol, changeShowDetails, symbolData }) {
  const data = Object.entries(symbolData[0]);
  const price = usd.format(symbolData[0].price);
  const change_color = symbolData[0].change < 0 ? "red" : "green";
  const change = usdChange.format(symbolData[0].change);
  const market_cap = usdCompact.format(symbolData[0].marketCap);
  const percent = symbolData[0].percent;
  const [historicalData, setHistoricalData] = useState({ historical: [], today: [], fiveDay: [] });
  const [range, setRange] = useState("fiveYear");
  const url =
    data.type !== "ETF"
      ? `https://www.powerinvest.pro/${symbol}.svg`
      : `https://www.powerinvest.pro/ETF.svg`;

  const [activeButton, setActiveButton] = useState("fiveYear");
  useEffect(
    () => {
      const updateData = async () => {
        let data = await getPriceData(symbol);
        setHistoricalData(data);
        const moreData = await getDetailPriceData(symbol);
        data = { ...data, ...moreData };
        setHistoricalData(data);
      };
      updateData();
    },
    // eslint-disable-next-line
    []
  );

  const currentYear = new Date().getFullYear();
  const title = {
    fiveYear: "5 Year Price",
    oneYear: "1 Year Price",
    sixMonths: "6 Month Price",
    threeMonths: "3 Month Price",
    oneMonth: "One Month Price",
    fiveDay: "5 Day Price",
    today: "One Day Price",
    yearToDate: `${currentYear} YTD Price`,
  };
  const BackButton = () => (
    <div className="mx-auto w-11/12 max-w-[1200px] sm:mt-3">
      <button className={styles.listOptions} onClick={() => changeShowDetails(false)}>
        {"⬅ Back"}
      </button>
    </div>
  );
  const GraphTitle = () => (
    <h4 className="my-0 py-2 text-center text-lg">
      {symbolData[0].name}&nbsp;{title[range]}
    </h4>
  );
  const isCrypto = symbolData[0].type === "CRYPTOCURRENCY"; //TODO - disabled these for now until we add the data

  const DateButtons = () => {
    const rangeLabels = {
      ...(!isCrypto ? { today: "1D" } : {}),
      ...(!isCrypto ? { fiveDay: "5D" } : {}),
      oneMonth: "1M",
      threeMonths: "3M",
      sixMonths: "6M",
      yearToDate: "YTD",
      oneYear: "1Y",
      fiveYear: "5Y",
    };
    return (
      <div className="mx-auto mt-4 grid w-4/5 grid-cols-4 gap-x-6 gap-y-4  sm:w-2/3 lg:grid-cols-8">
        {Object.keys(rangeLabels).map((key) => (
          <button
            className={`cursor-pointer rounded py-2 px-1.5 font-bold hover:bg-blue-300 sm:col-span-1 sm:px-4 ${
              activeButton === key
                ? "bg-blue-500 text-white"
                : "bg-gray-200 disabled:cursor-wait  disabled:text-black"
            }`}
            disabled={!historicalData[key] && (key === "today" || key === "fiveDay")}
            onClick={() => {
              setRange(key);
              setActiveButton(key);
            }}
          >
            {rangeLabels[key]}
          </button>
        ))}
      </div>
    );
  };

  const statistics = [
    { label: "PE Ratio", value: symbolData[0].pe },
    !isCrypto ? { label: "EPS", value: "$" + symbolData[0].eps } : {},
  ];
  const descript = [
    !isCrypto ? { label: "Exchange", value: symbolData[0].exchange } : {},
    {
      label: "Asset Type",
      value: isCrypto ? "Crypto Currency" : symbolData[0].type,
    },
  ];

  const priceStatistics = [
    { label: "Volume", value: symbolData[0].volume },
    { label: "52W Range", value: `${symbolData[0].yearLow} - ${symbolData[0].yearHigh}` },
  ];

  const PriceStats = ({ arr }) => {
    return (
      <div className="hidden flex-col flex-nowrap justify-center gap-y-4 whitespace-nowrap text-xl   md:flex">
        {arr
          .filter((s) => s.value)
          .map((stat) => (
            <div className="flex flex-col items-end gap-x-2 pb-0 text-left">
              <div className="text-base leading-4 text-gray-800">{stat.label}</div>
              <b className="leading-5">{stat.value}</b>
            </div>
          ))}
      </div>
    );
  };

  const truncate = (str) => {
    if (str.length < 32) return str;
    else {
      const breakChars = [" ", ",", "-"];
      let i = 0;
      while (!breakChars.includes(str[31 + i]) && i + 31 < str.length) i++;
      return str.substring(0, 32 + i);
    }
  };

  return (
    <>
      <BackButton />
      <div className="mx-auto mt-2.5 w-full max-w-[1200px] rounded bg-white p-1">
        <div className="ml-12 flex flex-col gap-x-1 sm:mx-6  sm:flex-row sm:items-center sm:gap-x-8">
          <div className="flex items-center gap-x-4 px-1 text-2xl font-bold sm:min-w-[30%] sm:max-w-[40%] sm:py-6 sm:text-3xl">
            <img src={url} alt="company logo" className="w-10 rounded sm:w-16" />
            <div>
              <div className="">
                {truncate(symbolData[0].name)}
                <span className="text-xl"> ({symbol})</span>
              </div>
              <div className="flex items-end gap-x-2 text-left text-2xl">
                <div className=" font-bold">{market_cap}</div>
                <div className="whitespace-nowrap text-xl font-normal text-gray-700">
                  Market Cap
                </div>
              </div>
            </div>
          </div>
          <div className="my-2.5 ml-10 flex items-end gap-x-2 whitespace-nowrap  text-3xl font-bold sm:ml-0 sm:flex-col sm:items-start sm:justify-center sm:gap-y-0.5 sm:text-4xl">
            <div>{price}</div>
            <div className="text-xl sm:text-xl" style={{ color: change_color }}>
              {change} ({percent.toFixed(2)}%)
            </div>
          </div>
          <div className="ml-10 flex w-full justify-between ">
            <PriceStats arr={priceStatistics} />
            <PriceStats arr={statistics} />
            <PriceStats arr={descript} />
          </div>
        </div>
      </div>

      <div className="mt-2.5 flex w-full max-w-[1200px] flex-col  items-center justify-around gap-4 rounded bg-white py-6 sm:flex-row md:mx-auto">
        <div className="w-full border-gray-300 py-1 px-2 sm:w-4/5 sm:px-8">
          <GraphTitle />
          <Graph symbol={symbol} price={symbolData[0].price} data={historicalData} range={range} />
          <DateButtons />
        </div>
      </div>
    </>
  );
}

export default Details;
