export const usd = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
});
export const usdCompact = new Intl.NumberFormat('en', {
    // style: 'currency',
    style: 'decimal',
    currency: 'USD',
    notation: 'compact',
    maximumFractionDigits: 2,
    minimumSignificantDigits: 3,
    maximumSignificantDigits: 3
});
export const pe = new Intl.NumberFormat('en', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});
export const usdChange = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    signDisplay: 'exceptZero',
    maximumFractionDigits: 2,
    roundingIncrement: 1,
    significantDigits: 4
});
export const percentChange = new Intl.NumberFormat('en', {
    style: 'unit',
    unit: 'percent',
    signDisplay: 'exceptZero',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});