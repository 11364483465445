//prettier-ignore
//for screener
export const columns = [
    { name: 'Symbol', descend: true, sortFunction: sortStr, hide: false, property: 'symbol', width: "8%" },
    { name: 'Name', descend: true, sortFunction: sortStr, hide: false, property: 'name', width: "25%" },
    { name: 'Price', descend: true, sortFunction: sortNum, hide: false, property: 'price', width: "10%" },
    { name: 'Market Cap', descend: true, sortFunction: sortNum, hide: false, property: 'marketCap', width: "10%" },
    { name: 'PE Ratio', descend: true, sortFunction: sortNum, hide: false, property: 'pe', width: "10%" },
    { name: 'Volume', descend: true, sortFunction: sortNum, hide: false, property: 'volume', width: "8%" },
    { name: '$ Change ', descend: true, sortFunction: sortNum, hide: false, property: 'change', width: "10%" },
    { name: '% Change ', descend: true, sortFunction: sortNum, hide: false, property: 'percent', width: "10%" },

]
//prettier-ignore

//for watch lists
export const ListColumns = [
    { name: 'Symbol', descend: true, sortFunction: sortStr, hide: false, property: 'symbol', width: "8%" },
    { name: 'Name', descend: true, sortFunction: sortStr, hide: false, property: 'name', width: "10%" }, //todo- room for notes collumn, name is large
    { name: 'Price', descend: true, sortFunction: sortNum, hide: false, property: 'price', width: "10%" },
    { name: '$ Change ', descend: true, sortFunction: sortNum, hide: false, property: 'change', width: "10%" },
    { name: '% Change ', descend: true, sortFunction: sortNum, hide: false, property: 'percent', width: "10%" },
    { name: 'Buy Target ', descend: true, sortFunction: sortNum, hide: false, property: 'percent', width: "10%" },
    { name: 'Sell Target ', descend: true, sortFunction: sortNum, hide: false, property: 'percent', width: "10%" },
    { name: 'Notes ', descend: true, sortFunction: sortNum, hide: false, property: 'percent', width: "10%"}
]

function sortStr(ascend, property) {
  if (ascend) {
    return () =>
      function (a, b) {
        let a2 = a[property] === undefined ? "" : a[property];
        let b2 = b[property] === undefined ? "" : b[property];
        return a2.localeCompare(b2);
      };
  } else {
    return () =>
      function (a, b) {
        let a2 = a[property] === undefined ? "" : a[property];
        let b2 = b[property] === undefined ? "" : b[property];
        return b2.localeCompare(a2);
      };
  }
}

function sortNum(ascend, property) {
  if (ascend) {
    return () =>
      function (a, b) {
        let a2 = a[property] === undefined ? 0 : a[property];
        let b2 = b[property] === undefined ? 0 : b[property];
        return b2 - a2;
      };
  } else {
    return () =>
      function (a, b) {
        let a2 = a[property] === undefined ? 0 : a[property];
        let b2 = b[property] === undefined ? 0 : b[property];
        return a2 - b2;
      };
  }
}
