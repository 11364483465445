export async function stockCall(old_data, changeLastUpdate) {
  try {
    const response = await fetch("/data", {
      method: "GET",
      // headers: { token: sessionStorage.token },
      credentials: "include",
    });

    if (!response.ok) {
      //TODO figure out pattern based on response - i.e. connection issue vs token invalid
      console.log("Error fetching data from server. ", response.status);
      return old_data;
      //   return [{}];
    }

    const data = await response.json();

    console.log("DATA UPDATED: " + data.length + " Tickers @ " + new Date().toLocaleTimeString());

    if (data.length === undefined) {
      console.log("Server error, undefined data.");
      return old_data;
    }
    changeLastUpdate(new Date());
    return data;
  } catch (err) {
    console.log("Network error.");
    console.error(err);
    return old_data;
  }
}

export async function getPriceData(symbol) {
  const response = await fetch(`/data/${symbol}`, {
    method: "GET",
    // headers: { token: sessionStorage.token },
    credentials: "include",
  });
  if (!response.ok) {
    console.log("Error fetching data from server. ", response.status);
    return {};
  }
  const data = await response.json();
  return data;
}

export async function getDetailPriceData(symbol) {
  const response = await fetch(`/data/detail/${symbol}`, {
    method: "GET",
    // headers: { token: sessionStorage.token },
    credentials: "include",
  });
  if (!response.ok) {
    console.log("Error fetching data from server. ", response.status);
    return {};
  }
  const data = await response.json();
  return data;
}
