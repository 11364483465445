import React from 'react';
import './global.css';
import App from './App'
import { createRoot } from 'react-dom/client';
import 'tippy.js/dist/tippy.css';


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);