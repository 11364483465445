import { useState, useEffect } from "react";
import { getSelected, toggleHighlight } from "./style";
import { Cells, WatchlistCells } from "./tableCells";
import { columns, ListColumns } from "./columns";
import { usd } from "./formater";
import { HideIcon, UnhideIcon, GearIcon, Add, Remove, Check, BellIcon, CloseIcon } from "./icons";
import { updateUserWatchlists, updateUserTargets } from "./userData";
import Modal from "react-modal";
import styles from "./app.module.css";
import Tippy from "@tippyjs/react";

export function StockTable({
  rawData,
  watchlists,
  changeWatchListState,
  currentTab,
  resetTab,
  searchString,
  targets,
  changeTargetState,
  alerts,
  alert_prices,
  lastUpdate,
  showHidden,
  changeDetailSymbol,
  changeShowDetails,
  toggleHidden,
  notes,
  addNote,
  removeNote,
}) {
  //State constructors.
  const [sortBy, setSortBy] = useState(columns[3].sortFunction(true, "marketCap"));
  const [page, setPage] = useState(0);
  const [displayCount, setDisplayCount] = useState(15);
  const [showHighlight, setShowHighlight] = useState(false);
  const [toggleLogo, setToggleLogo] = useState(true);
  const [selectedList, setSelectedList] = useState(watchlists[1].name);
  const [selected, setSelected] = useState([]);
  const [toggleCompact, setToggleCompact] = useState(false);
  const [showStocks, setShowStocks] = useState(true);
  const [showCrypto, setShowCrypto] = useState(true);
  const [showETF, setShowETF] = useState(false);
  const [showFollowed, setShowFollowed] = useState(false);
  const [capSize, setCapSize] = useState(10000000);
  const [peSize, setPeSize] = useState(0);
  const [sortIcon, setSortIcon] = useState(3);
  const [allCheck, setAllCheck] = useState(false);

  //first and last index of displayed page(updates on render).
  const firstIndex = page * displayCount;
  const lastIndex = page * displayCount + displayCount;
  const followed = watchlists.slice(1).reduce((acc, s) => [...acc, ...s.list], []);
  const assetTypes = [
    showStocks ? "EQUITY" : "",
    showCrypto ? "CRYPTOCURRENCY" : "",
    showETF ? "ETF" : "",
  ];
  const searchData =
    currentTab === 0
      ? rawData
          .filter((s) => search(s, searchString))
          .filter((s) => assetTypes.includes(s.type))
          .filter((s) => s.marketCap >= capSize || (s.marketCap === undefined && capSize === 0))
          .filter((s) => s.pe <= peSize || s.type !== "EQUITY" || peSize === 0)
          .filter((s) => (showFollowed ? !followed.includes(s.symbol) : s))
      : rawData.filter((s) => search(s, searchString));

  //If search string, or filters change, set page to 0 to avoid bounds errors.
  useEffect(() => {
    setPage(0);
  }, [
    searchString,
    currentTab,
    showCrypto,
    showStocks,
    showETF,
    capSize,
    displayCount,
    showHidden,
  ]);

  useEffect(() => {
    if (displayCount > 15) setToggleCompact(true);
  }, [displayCount]);

  //Caches 100 additional logos in memory to avoid flickering visual effect due to render time.
  // eslint-disable-next-line
  const cacheSymbols = searchData.slice(Math.max(firstIndex - 50, 0), lastIndex + 50).map((s) => {
    const logo = new Image();
    logo.src = `https://www.powerinvest.pro/${s.symbol}.svg`;
    return logo;
  });

  return (
    <div
      className="flex outline-none"
      onWheel={handleWheel}
      onKeyDown={handleArrowPress}
      tabIndex="-1"
    >
      <div className="basis-1/5  text-left">
        {currentTab === 0 && <Screener />}
        {currentTab > 0 ? <ListSettings /> : null}
        <DisplaySettings />
      </div>
      <div className="shrink-0 grow-0 basis-3/5">
        <table className={styles.stock_table}>
          <StockTableHeader setSortBy={setSortBy} />
          <StockPage
            arr={
              searchData.length
                ? searchData.sort(sortBy).slice(firstIndex, lastIndex)
                : [{ name: "No Items Found", symbol: "" }]
            }
          />
        </table>
        <div className="flex items-center justify-between">
          {currentTab === 0 ? <HideTab /> : <NoHide />}
          <Pagination />
          <Counts />
        </div>
      </div>
      <div className="basis-1/5">
        <Notifications />
        {selected.length > 0 && <SelectBox watchlists={watchlists} />}
      </div>
    </div>
  );

  //Handles dynamic table headers and sorts from click.
  function StockTableHeader({ setSortBy }) {
    function selectAll() {
      if (allCheck === false) {
        setSelected(searchData.map((s) => s.symbol));
        setAllCheck(true);
      } else {
        setSelected([]);
        setAllCheck(false);
      }
    }

    return (
      <thead className="cursor-pointer  text-left text-white">
        <tr>
          <th className="bg-[#5369f8]">
            <div className="flex justify-center pt-0.5">
              <input
                className="h-[22px] w-[22px]"
                type="checkbox"
                checked={allCheck}
                onChange={() => selectAll()}
              ></input>
            </div>
          </th>
          {currentTab === 0
            ? columns.map((col, i) => (
                <th
                  onClick={() => toggleHeader(i)}
                  key={col.name}
                  style={{ width: col.width }}
                  className="bg-[#5369f8] text-center"
                >
                  {" "}
                  {col.name} {sortIcon === i && (!columns[i].descend ? "⯅" : "⯆")}
                </th>
              ))
            : ListColumns.map((col, i) => (
                <th
                  className="bg-[#5369f8] text-center"
                  onClick={() => toggleHeader(i)}
                  key={col.name}
                  style={{ width: col.width }}
                >
                  {" "}
                  {col.name} {sortIcon === i && (!columns[i].descend ? "⯅" : "⯆")}
                </th>
              ))}
        </tr>
      </thead>
    );

    function toggleHeader(index) {
      setSortIcon(index);
      //Sorts screener
      if (currentTab === 0) {
        columns[index].descend = !columns[index].descend;
        setSortBy(columns[index].sortFunction(columns[index].descend, columns[index].property));
      }
      //Sorts watchlist
      else {
        ListColumns[index].descend = !ListColumns[index].descend;
        setSortBy(
          ListColumns[index].sortFunction(ListColumns[index].descend, ListColumns[index].property)
        );
      }
    }
  }

  function StockRow(props) {
    const CheckBox = () => {
      return (
        <td className="w-[2%]">
          <div className="flex items-center justify-center">
            <input
              className="h-[22px] w-[22px]"
              type="checkbox"
              checked={check}
              onChange={handleSelect}
              name={props.symbol}
            ></input>
          </div>
        </td>
      );
    };
    const check = props.selected.includes(props.symbol);

    function handleSelect(event) {
      if (event.shiftKey) {
        const symbols = searchData.map((s) => s.symbol);
        const first = symbols.indexOf(selected[selected.length - 1]) + 1;
        const last = symbols.indexOf(props.symbol) + 1;
        //handles shift clicking down in list and shift clicking up (selecting earlier elements)
        const temp_set = new Set(
          first < last
            ? [...selected, ...symbols.slice(first, last)]
            : [...selected, ...symbols.slice(last - 1, first - 1)]
        );
        return setSelected([...temp_set]);
      }

      if (props.selected.includes(props.symbol)) {
        setSelected(selected.filter((z) => z !== props.symbol));
      } else setSelected([...selected, props.symbol]);
    }

    return (
      <tr
        key={props.symbol}
        symbol={props.symbol}
        onClick={handleSelect}
        style={{
          ...getSelected(check),
          ...toggleHighlight(props.percent, props.showHighlight),
        }}
      >
        <CheckBox />
        {currentTab === 0 ? <Cells {...props} /> : <WatchlistCells {...props} />}
      </tr>
    );
  }

  function StockPage({ arr }) {
    return (
      <tbody>
        {arr.map((stock) => (
          <StockRow
            {...stock}
            type={stock.type}
            key={stock.symbol}
            toggleSelect={setSelected}
            selected={selected}
            showHighlight={showHighlight}
            toggleLogo={toggleLogo}
            toggleCompact={toggleCompact}
            targets={targets}
            newSellTarget={newSellTarget}
            newBuyTarget={newBuyTarget}
            clearSellTarget={clearSellTarget}
            clearBuyTarget={clearBuyTarget}
            alerts={alerts}
            changeDetailSymbol={changeDetailSymbol}
            changeShowDetails={changeShowDetails}
            note={notes[stock.symbol]}
            addNote={addNote}
            removeNote={removeNote}
          />
        ))}
      </tbody>
    );
  }

  function Counts() {
    let last = lastIndex >= searchData.length ? searchData.length : lastIndex;
    let first = searchData.length <= 0 ? 0 : firstIndex + 1;
    return (
      <div className="-mt-2.5 text-xl text-white">
        ({first} - {last}) of {Intl.NumberFormat("en-US").format(searchData.length)} &nbsp;
        <br />
      </div>
    );
  }

  function HideTab() {
    const bg = showHidden ? { backgroundColor: "#f3c268" } : {};
    return (
      <button
        className="h-8 cursor-pointer rounded border-2 border-black bg-white px-4 text-[15px] font-bold hover:bg-[#fec458]"
        // className={styles.tab}
        onClick={() => {
          resetTab();
          toggleHidden();
        }}
        style={bg}
      >
        Hidden {showHidden ? <UnhideIcon /> : <HideIcon />}
      </button>
    );
  }
  //On a watchlist Tab, informs user they cannot hide tracked items.
  function NoHide() {
    return (
      <>
        <Tippy content="Cannot Hide Followed Items" placement="left">
          <button className="h-8  cursor-not-allowed rounded border-2 border-black bg-white px-4 text-[15px] font-bold  opacity-50">
            Hidden <HideIcon />{" "}
          </button>
        </Tippy>
      </>
    );
  }

  function DisplaySettings() {
    return (
      <div className={styles.settings}>
        <h2 style={{ marginLeft: "4%" }}>Display Settings</h2>
        <table className={styles.settings_table}>
          <tbody>
            <tr onClick={() => setToggleLogo(!toggleLogo)} className="cursor-pointer select-none">
              <td> Show Logos&nbsp;&nbsp;</td>
              <td style={{ textAlign: "center" }}>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={toggleLogo}
                    onChange={() => setToggleLogo(!toggleLogo)}
                  ></input>
                  <span className="slider round"></span>
                </label>
              </td>
            </tr>
            <tr
              onClick={() => setShowHighlight(!showHighlight)}
              className="cursor-pointer select-none"
            >
              <td>Highlight Big Gains / Loses&nbsp;&nbsp; </td>
              <td style={{ textAlign: "center" }}>
                {" "}
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={showHighlight}
                    onChange={() => setToggleLogo(!toggleLogo)}
                  ></input>
                  <span className="slider round"></span>
                </label>
              </td>
            </tr>
            <tr
              onClick={() => setToggleCompact(!toggleCompact)}
              className="cursor-pointer select-none"
            >
              <td>View Compact Table&nbsp;&nbsp; </td>
              <td style={{ textAlign: "center" }}>
                {" "}
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={toggleCompact}
                    onChange={() => setToggleLogo(!toggleLogo)}
                  ></input>
                  <span className="slider round"></span>
                </label>
              </td>
            </tr>
            <tr onClick={() => null} className="select-none">
              <td>Stocks Per Page </td>
              <td style={{ textAlign: "right" }}>
                <select
                  className=" font-serif mr-2.5 w-12 rounded border-2 border-black bg-white text-base outline-none"
                  value={displayCount}
                  onChange={(e) => setDisplayCount(parseInt(e.target.value))}
                >
                  <option value={5}>5</option>
                  <option value={10}>10 </option>
                  <option value={15}>15 </option>
                  {/* <option title='compact' value={20}>20 </option> */}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
      </div>
    );
  }

  function Screener() {
    return (
      <div className={styles.settings}>
        <h2 style={{ marginLeft: "4%" }}>Screen Filters</h2>
        <table className={styles.settings_table}>
          <tbody>
            <tr onClick={() => setShowStocks(!showStocks)} className="cursor-pointer select-none">
              <td>Show Stocks </td>
              <td style={{ textAlign: "right", paddingRight: 10 }}>
                {" "}
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={showStocks}
                    onChange={() => setShowStocks(!showStocks)}
                  ></input>{" "}
                  <span className="slider round"></span>
                </label>
              </td>
            </tr>

            <tr onClick={() => setShowCrypto(!showCrypto)} className="cursor-pointer select-none">
              <td>Show Crypto </td>
              <td style={{ textAlign: "right", paddingRight: 10 }}>
                {" "}
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={showCrypto}
                    onChange={() => setShowStocks(!showCrypto)}
                  ></input>{" "}
                  <span className="slider round"></span>
                </label>
              </td>
            </tr>

            <tr onClick={() => setShowETF(!showETF)} className="cursor-pointer select-none">
              <td>Show ETFs </td>
              <td style={{ textAlign: "right", paddingRight: 10 }}>
                {" "}
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={showETF}
                    onChange={() => setShowStocks(!showETF)}
                  ></input>{" "}
                  <span className="slider round"></span>
                </label>
              </td>
            </tr>
            <tr className="select-none">
              <td>Market Cap &nbsp;</td>
              <td style={{ textAlign: "right", paddingRight: 10 }}>
                <select
                  className=" font-serif  w-[90%] rounded border-2 border-black bg-white text-base outline-none"
                  value={capSize}
                  onChange={(e) => setCapSize(Number(e.target.value))}
                >
                  <option value={0}>Any Size</option>
                  <option value={10_000_000}>$10+ Million</option>
                  <option value={50_000_000}>$50+ Million</option>
                  <option value={200_000_000}>$200+ Million</option>
                  <option value={2_000_000_000}>$2+ Billion</option>
                  <option value={50_000_000_000}>$50+ Billion</option>
                  <option value={100_000_000_000}>$100+ Billion</option>
                </select>
              </td>
            </tr>
            <tr className="select-none">
              <td>PE Ratio &nbsp;</td>
              <td style={{ textAlign: "right", paddingRight: 10 }}>
                <select
                  value={peSize}
                  className=" font-serif w-[90%] rounded border-2 border-black  bg-white text-base outline-none"
                  onChange={(e) => setPeSize(Number(e.target.value))}
                >
                  <option value={-0.0001}>Negative</option>
                  <option value={0}>Any</option>
                  <option value={999999999999}>0+ Possitive</option>
                  <option value={5}>5 and Under</option>
                  <option value={10}>10 and Under</option>
                  <option value={15}>15 and Under</option>
                  <option value={30}>30 and Under</option>
                  <option value={60}>60 and Under</option>
                </select>
              </td>
            </tr>
            <tr
              onClick={() => setShowFollowed(!showFollowed)}
              className="cursor-pointer select-none"
            >
              <td style={{}}>Exclude Followed</td>
              <td style={{ textAlign: "right", paddingRight: 10 }}>
                {" "}
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={showFollowed}
                    onChange={() => setShowFollowed(!showFollowed)}
                  ></input>{" "}
                  <span className="slider round"></span>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
      </div>
    );
  }

  function SelectBox() {
    let listIndex = watchlists.findIndex((s) => s.name === selectedList);

    function Count() {
      return <span style={{ fontWeight: 600 }}>{selected.length}</span>;
    }

    function Buttons() {
      const HideButton = () => {
        if (!showHidden)
          return (
            <button
              className="mr-2.5 mt-2.5 cursor-pointer rounded border-2 border-black bg-white text-sm font-bold hover:bg-slate-200"
              onClick={() => {
                addSelected(selected, 0);
                setSelected([]);
                setAllCheck(false);
              }}
            >
              <HideIcon /> HIDE SELECTED{" "}
            </button>
          );
        else
          return (
            <button
              className="mr-2.5 mt-2.5 cursor-pointer rounded border-2 border-black bg-white text-sm font-bold hover:bg-slate-200"
              onClick={() => {
                removeSelected(selected, 0);
                setSelected([]);
                setAllCheck(false);
              }}
            >
              <UnhideIcon /> UNHIDE SELECTED{" "}
            </button>
          );
      };

      if (currentTab === 0) {
        return (
          <ul>
            <button
              className="mr-2.5 mt-2.5 cursor-pointer rounded border-2 border-black bg-white text-sm font-bold hover:bg-slate-200"
              onClick={() => {
                addSelected(selected, listIndex);
                setSelected([]);
                setAllCheck(false);
              }}
            >
              <Add /> ADD TO
            </button>
            &nbsp;
            <ListSelector />
            <HideButton />
            <button
              className="mr-2.5 mt-2.5 cursor-pointer rounded border-2 border-black bg-white text-sm font-bold hover:bg-slate-200"
              onClick={() => {
                setSelected([]);
                setAllCheck(false);
              }}
            >
              CLEAR SELECTED
            </button>
          </ul>
        );
      }
      if (currentTab > 0) {
        return (
          <ul>
            <button
              className="mr-2.5 mt-2.5 cursor-pointer rounded border-2 border-black bg-white text-sm font-bold hover:bg-slate-200"
              onClick={() => {
                addSelected(selected, listIndex);
                setSelected([]);
              }}
            >
              <Add /> ADD TO
            </button>
            &nbsp;
            <ListSelector />
            <button
              className="mr-2.5 mt-2.5 cursor-pointer rounded border-2 border-black bg-white text-sm font-bold hover:bg-slate-200"
              // style={{ marginTop: 10 }}
              onClick={() => removeSelected(selected, currentTab)}
            >
              <Remove />
              REMOVE FROM <b>{watchlists[currentTab].name}</b>
            </button>
            <br />
            <button
              className="mr-2.5 mt-2.5 cursor-pointer rounded border-2 border-black bg-white text-sm font-bold hover:bg-slate-200"
              onClick={() => {
                setSelected([]);
                setAllCheck(false);
              }}
              // style={{ marginTop: 10, height: 24 }}
            >
              CLEAR SELECTED
            </button>
          </ul>
        );
      }
    }

    return (
      <div className={styles.selectsRight} className="mx-10 rounded-xl bg-[#f3c268] py-4 px-2.5">
        <ul className="mb-0  pb-0">
          <Count /> Stock(s) Selected: <br />
          <span className="font-bold text-[#233de6]">
            {selected.slice(0, 5).join(", ")}
            {selected.length > 5 && <>...</>}
          </span>
        </ul>
        <Buttons />
      </div>
    );
  }

  function ListSettings() {
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    function renameList(newName) {
      if (!newName.length) return;
      let newLists = [...watchlists];
      newLists[currentTab].name = newName;
      changeWatchListState(newLists);
      updateUserWatchlists(newLists);
    }

    function RenameModal() {
      const [listName, setListName] = useState("");
      const nameLength = 20;

      const modalStyles = {
        content: {
          top: "25%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "20%",
          backgroundColor: "#3353ac",
          color: "white",
        },
      };
      const CloseButton = () => {
        return (
          <span onClick={() => setModalOpen(false)}>
            <CloseIcon />
          </span>
        );
      };

      return (
        <Modal isOpen={modalOpen} style={modalStyles} autoFocus={false} ariaHideApp={false}>
          <CloseButton />
          <div
            style={{
              height: "80px",
              marginTop: 30,
              marginBottom: 15,
              marginLeft: 20,
            }}
          >
            <div
              // style={{ marginBottom: 10, fontSize: 20, fontWeight: 700 }}
              className="mb-3 text-lg font-bold"
            >
              Rename <b>"{watchlists[currentTab].name}"</b>
            </div>
            <input
              autoFocus={true}
              style={{
                width: "70%",
                marginRight: 6,
                height: "2em",
                fontSize: "1em",
                fontWeight: 700,
              }}
              onChange={(e) => setListName(e.target.value)}
              onKeyDown={(e) => (e.key === "Enter" ? renameList(e.target.value) : null)}
              type="text"
              placeholder={watchlists[currentTab].name}
              autoComplete="off"
              maxLength={nameLength}
              value={listName}
            />
            <button style={{ height: "2em", fontSize: 16 }} onClick={() => renameList(listName)}>
              <b>Submit</b>
            </button>
          </div>
        </Modal>
      );
    }

    function DeleteModal() {
      const modalStyles = {
        content: {
          top: "25%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "20%",
          backgroundColor: "#3353ac",
          color: "white",
        },
      };
      const CloseButton = () => {
        return (
          <span onClick={() => setDeleteModalOpen(false)}>
            <CloseIcon onClick={() => setDeleteModalOpen(false)} />
          </span>
        );
      };

      return (
        <Modal isOpen={deleteModalOpen} style={modalStyles} autoFocus={false} ariaHideApp={false}>
          <CloseButton />
          <div className="mt-8 mb-4 h-20 select-none pl-14">
            <div className="mb-2.5 text-xl font-bold">Delete "{watchlists[currentTab].name}"?</div>
            <span style={{ textAlign: "center" }}>
              <button
                className="mr-5 h-8 cursor-pointer rounded-sm px-4 text-base text-red-600 hover:bg-slate-300"
                onClick={() => deleteWatchList(currentTab)}
              >
                <b>Delete</b>
              </button>
              <button
                className="mr-5 h-8 cursor-pointer rounded-sm px-4 text-base text-black hover:bg-slate-300"
                onClick={() => setDeleteModalOpen(false)}
              >
                <b>Cancel</b>
              </button>
            </span>
          </div>
        </Modal>
      );
    }

    //Disable delete button first the default watchlist (current tab index 1)
    const deleteStyle =
      currentTab === 1
        ? {
            paddingLeft: 5,
            paddingRight: 5,
            width: "55%",
            color: "grey",
            opacity: "50%",
            borderWidth: 2,
            borderColor: "black",
          }
        : {
            paddingLeft: 5,
            paddingRight: 5,
            width: "55%",
            borderWidth: 2,
            borderColor: "gray",
          };
    const deleteTitle = currentTab === 1 ? "Default Watchlist cannot be deleted." : null;

    return (
      <div className={styles.selectsLeft}>
        <h3 className="text-center">
          {watchlists[currentTab].name} Settings <GearIcon />
        </h3>

        <ul className="text-center">
          <button
            className={styles.listOptions}
            style={{
              paddingLeft: 5,
              paddingRight: 5,
              width: "55%",
              marginBottom: 10,
              borderWidth: 2,
              borderColor: "black",
            }}
            onClick={() => setModalOpen(true)}
          >
            <b style={{ color: "blue " }}>RENAME LIST</b>
          </button>
          <RenameModal />
          <br />
          <button
            className={styles.listOptions}
            disabled={currentTab === 1}
            title={deleteTitle}
            style={deleteStyle}
            onClick={() => setDeleteModalOpen(true)}
          >
            <b style={{ color: "red " }}>DELETE LIST</b>
          </button>
          <DeleteModal />
        </ul>
        <p className="text-center text-sm">
          ({watchlists[currentTab].list.length}) items in {watchlists[currentTab].name}
        </p>
      </div>
    );
  }

  function Notifications() {
    if (alerts.length > 0) document.title = ` 🔔 (${alerts.length}) Power Invest Pro`;
    else document.title = "Power Invest Pro";

    //TODO does not show both a buy and sell notification for the same security. Not a super likely occurance though.
    function BuySell({ stock, price }) {
      if (targets[stock] === undefined) return null;

      return targets[stock].buy === undefined || targets[stock].buy < alert_prices[stock] ? (
        <ul className="font-semibold text-[#ed1916]">
          <Check /> {stock} sell target of {usd.format(targets[stock].sell)}{" "}
          <span className="text-black">@ {usd.format(price)}</span>
        </ul>
      ) : (
        <ul className=" font-semibold text-green-600">
          <Check /> {stock} buy target of {usd.format(targets[stock].buy)}{" "}
          <span className="text-black">@ {usd.format(price)}</span>
        </ul>
      );
    }

    return (
      <div className={styles.selectsRight}>
        <h2 className="mt-4 mb-4 ml-4 text-left">
          <BellIcon alertCount={alerts.length} /> Price Alerts (
          {alerts.length === 0 ? "None" : alerts.length})
        </h2>

        {Object.entries(alert_prices).map(([stock, price]) => (
          <BuySell stock={stock} price={price} key={stock} />
        ))}

        {/* <ul className=" my-2 text-sm ">({Object.keys(targets).length} Targets Total)</ul> */}
        <ul className="text-sm font-bold">
          Last Updated {lastUpdate.toLocaleTimeString()}
          {lastUpdate - new Date() < -120000 && (
            <p className="mb-0 pb-0 font-bold text-orange-500">
              ⚠️ Connection Error - Showing Old Data{" "}
            </p>
          )}
        </ul>
      </div>
    );
  }

  function Pagination() {
    //If on page 0 or lower, stay on page 0;
    const pageUp = () => (page <= 0 ? setPage(0) : setPage(page - 1));
    //If viewing last stock do not page down.
    const pageDown = () => (lastIndex >= searchData.length ? null : setPage(page + 1));

    return (
      <div className="mt-1">
        <span>
          <button className={styles.pagination} onClick={pageUp}>
            &laquo;{" "}
          </button>
          &nbsp;
          <button className={styles.pagination} onClick={pageDown}>
            {" "}
            &raquo;
          </button>
        </span>
      </div>
    );
  }

  function ListSelector() {
    return (
      <select
        className="font-serif h-6  cursor-pointer rounded border-2 border-black bg-white text-base font-bold outline-none hover:border-2 hover:border-black hover:bg-gray-100"
        value={selectedList}
        onChange={(e) => setSelectedList(e.target.value)}
      >
        {watchlists.slice(1).map((list) => (
          <option className="font-bold " key={list.name} value={list.name}>
            {list.name}
          </option>
        ))}
      </select>
    );
  }

  function addSelected(selectedStocks, index) {
    //creates templist set from selected stocks and watchlist
    let tempList = new Set(selectedStocks);
    watchlists[index].list.forEach((s) => tempList.add(s));

    //create a copy of watchlists array and replace with set at index.
    let storedList = watchlists;
    storedList[index].list = Array.from(tempList);
    //Update state and save to local storage.
    changeWatchListState([...storedList]);
    updateUserWatchlists(storedList);
    setSelected([]);
  }

  function removeSelected(selectedStocks, index) {
    //creates new watchlist object with list filtering out selected values
    let tempList = watchlists[index].list.filter((s) => !selectedStocks.includes(s));
    let tempObj = { name: watchlists[index].name, list: tempList };
    let newLists = [...watchlists.slice(0, index), tempObj, ...watchlists.slice(index + 1)];
    //sets new watchlist state
    changeWatchListState(newLists);
    updateUserWatchlists(newLists);
    setSelected([]);
  }

  function deleteWatchList(index) {
    let newLists = [...watchlists.slice(0, index), ...watchlists.slice(index + 1)];
    changeWatchListState(newLists);
    updateUserWatchlists(newLists);
    resetTab();
  }

  function newSellTarget(symbol, price) {
    if (isNaN(Number(price) || price === "")) return alert(price + " is not a number!");
    if (Number(price) >= 1000000) {
      return alert("Price target of " + usd.format(price) + " exceeds $1 million dollars.");
    }
    if (Number(price) < 0) {
      return alert("Price target less than zero dollars.");
    }
    let newObj = { ...targets[symbol] };
    newObj["sell"] = Number(price);
    let newTargets = { ...targets, [symbol]: newObj };
    changeTargetState(newTargets);
    updateUserTargets(newTargets);
  }

  function newBuyTarget(symbol, price) {
    if (isNaN(Number(price || Number(price) === 0))) return alert(price + " is not a number!");
    if (Number(price) >= 1000000) {
      return alert("Price target of " + usd.format(price) + " exceeds $1 million dollars.");
    }
    if (Number(price) < 0) {
      return alert("Price target less than zero dollars.");
    }
    let newObj = { ...targets[symbol] };
    newObj["buy"] = Number(price);
    let newTargets = { ...targets, [symbol]: newObj };
    changeTargetState(newTargets);
    updateUserTargets(newTargets);
  }

  function clearBuyTarget(symbol) {
    let newTargets = { ...targets };
    if (newTargets.hasOwnProperty(symbol)) delete newTargets[symbol].buy;

    changeTargetState(newTargets);
    updateUserTargets(newTargets);
  }

  function clearSellTarget(symbol) {
    let newTargets = { ...targets };
    if (newTargets.hasOwnProperty(symbol)) delete newTargets[symbol].sell;

    changeTargetState(newTargets);
    updateUserTargets(newTargets);
  }

  function handleWheel(event) {
    //Mouse wheel scroll up
    if (event.nativeEvent.wheelDelta > 0) {
      //if page is 0 or lower do nothing(there aren't negative pages), otherwise decrease page.
      return page <= 0 ? setPage(page) : setPage(page - 1);
    }
    //if scroll down and not on last page incrase page number
    else return (page + 1) * displayCount >= searchData.length ? setPage(page) : setPage(page + 1);
  }

  function handleArrowPress(event) {
    if (
      (event.key === "ArrowDown" || event.key === "ArrowRight") &&
      (page + 1) * displayCount < searchData.length
    ) {
      setPage(page + 1);
    }
    if ((event.key === "ArrowUp" || event.key === "ArrowLeft") && page > 0) {
      setPage(page - 1);
    }
  }

  function search(val, input) {
    //To handle undefined names and symbols
    let name = val.name === undefined ? "" : val.name.toLowerCase();
    let symbol = val.symbol === undefined ? "" : val.symbol.toLowerCase();
    return symbol.includes(input.toLowerCase()) || name.includes(input.toLowerCase());
  }
}
