import { useEffect, useState } from "react";
import { stockCall } from "./DataFetcher";
import { StockTable } from "./StockTable";
import { tabSelected } from "./style";
import { PlusIcon, AlertIcon, CloseIcon, MembershipIcon, LogoutIcon } from "./icons";
import { accessBilling, deleteNote, updateUserWatchlists } from "./userData";
import Details from "./details";
//Visual Libaries
import Modal from "react-modal";
import { logoutUser } from "./userData";

function StockApp({ checkAuth }) {
  const [rawData, setRawData] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [currentTab, setCurrentTab] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [alerts, setAlerts] = useState([""]);
  const [targets, setTargets] = useState({});
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [showHidden, setShowHidden] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [detailSymbol, setDetailSymbol] = useState("AAPL");
  const [watchlists, setWatchLists] = useState([
    {
      name: "hidelist",
      list: ["GOOG", "BRK-A"],
    },
    {
      name: "Watchlist",
      list: [],
    },
  ]);
  const [notes, setNotes] = useState({ DOGE: "To the moon!" });

  useEffect(() => {
    getUserData();
    updateStocks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSearchString("");
  }, [currentTab]);

  //Check if price targets are met whenever data or targets change.
  useEffect(
    () => {
      checkTargets();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [targets, rawData]
  );

  const changeDetailSymbol = (newState) => {
    setDetailSymbol(newState);
  };

  const changeShowDetails = (newState) => {
    setShowDetails(newState);
  };

  const changeLastUpdate = (newState) => {
    setLastUpdate(newState);
  };

  const changeWatchListState = (newState) => {
    setWatchLists(newState);
  };

  const changeTargetState = (newState) => {
    setTargets(newState);
  };

  const resetTab = (newState) => {
    setCurrentTab(0);
  };

  const moveTab = (newState) => {
    setCurrentTab(newState);
  };

  const toggleHidden = (newState) => {
    setShowHidden(!showHidden);
  };

  const addNote = (note) => {
    setNotes({ ...notes, ...note });
  };

  const removeNote = (symbol) => {
    const { [symbol]: _, ...newNotes } = notes;
    deleteNote(symbol);
    setNotes(newNotes);
  };

  async function getUserData() {
    try {
      const response = await fetch("/user/", {
        method: "GET",
        // headers: { token: sessionStorage.token },
        credentials: "include",
      });
      const parseRes = await response.json();
      setWatchLists(parseRes.watchlist_data);
      setTargets(parseRes.target_data);
      setName(parseRes.user_name);
      setStatus(parseRes.membership_status);
      setNotes(parseRes.notes);
    } catch (err) {
      console.error(err.message);
    }
  }

  //authentication

  const alert_prices = rawData.reduce((acc, curr) => {
    return alerts.includes(curr.symbol) ? { ...acc, [curr.symbol]: curr.price } : acc;
  }, {});

  const WatchListData = rawData.filter((s) => watchlists[currentTab].list.includes(s.symbol));
  //The normal screener and hide list have same functionallity.
  const ScreenerData = showHidden
    ? rawData.filter((s) => watchlists[0].list.includes(s.symbol))
    : rawData.filter((s) => !watchlists[0].list.includes(s.symbol));

  return (
    <div className="min-h-screen bg-[#7285bb] py-2 sm:py-0">
      <TopBar />
      {currentTab === 0 && !showDetails && (
        <div className="mt-2.5 text-center text-5xl font-bold  text-white">{/* Search */}</div>
      )}
      {currentTab !== 0 && !showDetails && (
        <div className="mt-2.5 text-center text-5xl font-bold  text-white">{/* Track */}</div>
      )}
      <div style={!showDetails ? { display: "block" } : { display: "none" }}>
        <SearchBar handleSearch={handleSearch} searchString={searchString} />
        <TabBar />
        <StockTable
          rawData={currentTab === 0 ? ScreenerData : WatchListData}
          watchlists={watchlists}
          alert_prices={alert_prices}
          showHidden={showHidden}
          changeWatchListState={changeWatchListState}
          currentTab={currentTab}
          resetTab={resetTab}
          searchString={searchString}
          targets={targets}
          changeTargetState={changeTargetState}
          alerts={alerts}
          lastUpdate={lastUpdate}
          changeDetailSymbol={changeDetailSymbol}
          changeShowDetails={changeShowDetails}
          toggleHidden={toggleHidden}
          notes={notes}
          addNote={addNote}
          removeNote={removeNote}
        />
      </div>
      {showDetails && (
        <Details
          symbol={detailSymbol}
          changeShowDetails={changeShowDetails}
          symbolData={rawData.filter((s) => s.symbol === detailSymbol)}
        />
      )}
    </div>
  );

  function TopBar() {
    function capitalize(str) {
      if (str === "current") str = "Premium"; //todo - change on backend instead?
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    // return <></>;
    return (
      <div className="mt-0 hidden justify-between border-b-2 bg-[#1462c6]  pr-4 text-white sm:flex">
        <div className="flex select-none items-center text-[30px]">
          <img className="mx-2 w-20 " src="https://www.powerinvest.pro/logo2.gif" alt="logo" />
          Power Invest Pro
        </div>

        <div className="mr-2 flex select-none flex-col items-end justify-start  pt-2  text-xl">
          <div className="my-0 flex w-full flex-row justify-between gap-x-3 gap-y-1 py-0 text-lg">
            <div className="text-xl font-bold">Hello, {capitalize(name)}</div>
            {/* <div className="font-bold text-green-300">
              {capitalize(status)}
              Free Account
            </div> */}
          </div>

          {/* <div className="mb-1 flex items-end"> */}
          {/* <button
              className="mr-2 cursor-pointer rounded  border-2 border-black bg-white px-3 py-0.5 text-base font-bold hover:bg-gray-200"
              onClick={() => accessBilling()}
            >
              <MembershipIcon /> Account
            </button> TODO- turned off since free */}

          <button
            className="cursor-pointer whitespace-nowrap rounded border-2  border-black bg-white px-3 py-0.5 text-right text-base font-bold hover:bg-gray-200"
            onClick={() => {
              logoutUser();
            }}
          >
            <LogoutIcon /> Sign Out
          </button>
          {/* </div> */}
        </div>
      </div>
    );
  }

  function TabBar() {
    const [modalOpen, setModalOpen] = useState(false);

    const listLimit = 30;
    const nameLength = 20;

    function ListModal() {
      const [listName, setListName] = useState("");
      const modalStyles = {
        content: {
          top: "25%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "20%",
          backgroundColor: "#3353ac",
          color: "white",
        },
      };
      const CloseButton = () => {
        return (
          <span onClick={() => setModalOpen(false)}>
            <CloseIcon onClick={() => setModalOpen(false)} />
          </span>
        );
      };

      return (
        <Modal isOpen={modalOpen} style={modalStyles} autoFocus={false} ariaHideApp={false}>
          <CloseButton />
          <div className=" mt-8 mb-4 h-20 pl-8">
            <div className="mb-2.5 text-xl font-bold">New List Name:</div>
            <input
              className="mr-1.5 h-8 w-3/5 text-base font-bold outline-none"
              autoFocus={true}
              onChange={(e) => setListName(e.target.value)}
              onKeyDown={(e) => (e.key === "Enter" ? createList(e.target.value) : null)}
              type="text"
              placeholder="Meme Stocks"
              autoComplete="off"
              maxLength={nameLength}
              value={listName}
            />
            <button
              className="h-8 cursor-pointer bg-white text-base hover:bg-gray-200"
              onClick={() => createList(listName)}
            >
              <b>Create</b>
            </button>
          </div>
        </Modal>
      );
    }

    return (
      <div className="my-0 mx-auto flex w-3/5 flex-wrap-reverse pl-3">
        <Tab name={"Stock Screener"} tabNum={0} list={[]} />
        {watchlists.slice(1).map((list, i) => (
          <Tab name={list.name} list={list.list} tabNum={i + 1} key={i} />
        ))}
        {watchlists.length <= listLimit && (
          <button
            className="h-8 cursor-pointer rounded border-2 border-black bg-white px-1.5 text-[15px] font-bold hover:bg-[#fec458]"
            onClick={() => setModalOpen(true)}
          >
            <PlusIcon />
          </button>
        )}
        <ListModal />
        {watchlists.length > listLimit && (
          <span className="pl-2.5 text-sm text-white">
            {" "}
            *List Limit ({listLimit} / {listLimit})
          </span>
        )}
      </div>
    );
  }

  function Tab({ name, tabNum, list }) {
    const alertCount = list.filter((s) => alerts.includes(s)).length;
    const NotifyBubble = () => (
      <span className="ml-1  rounded bg-red-500 px-1.5 py-0.5 text-base text-white">
        {alertCount}
      </span>
    );

    return (
      <button
        className="h-8 cursor-pointer rounded-t border-2 border-b-0 border-black bg-white px-4 text-[15px] font-bold hover:bg-[#fec458]"
        onClick={() => setCurrentTab(tabNum)}
        style={tabSelected(tabNum === currentTab)}
      >
        {tabNum !== 0 && alertCount > 0 && <AlertIcon />}
        {name}
        {tabNum !== 0 && alertCount > 0 && <NotifyBubble />}
      </button>
    );
  }

  function handleSearch(event) {
    setSearchString(event.target.value);
  }

  async function createList(newName) {
    if ([...watchlists].map((s) => s.name).includes(newName))
      return alert(`Watchlist name "${newName}" already exists!`);

    const newWatchlistData = [...watchlists, { name: newName, list: [] }];
    //PUT Request to update database
    updateUserWatchlists(newWatchlistData);
    //updates state
    setWatchLists(newWatchlistData);
    //moves tab to lattest watchlist
    moveTab(watchlists.length);
  }

  //Updates Raw Stock data when called, and updates on a timer.
  async function updateStocks() {
    await checkAuth(); //confirms user is authenticated (jwt valid)
    setRawData(await stockCall(rawData, changeLastUpdate));
    // setLastUpdate(new Date().toLocaleTimeString());
    setTimeout(updateStocks, 90 * 1000);
  }

  function checkTargets() {
    //creates a map of current prices for each symbol with a price target i.e. {AAPL:201.15}
    const current_prices = rawData.reduce((acc, curr) => {
      return Object.keys(targets).includes(curr.symbol)
        ? { ...acc, [curr.symbol]: curr.price }
        : acc;
    }, {});

    let newTargets = [];
    for (let stock in targets) {
      //If buy price or sell price is met add to targets array.
      if (
        current_prices[stock] <= targets[stock].buy ||
        current_prices[stock] >= targets[stock].sell
      )
        newTargets.push(stock);
    }
    setAlerts(newTargets);
  }
}

function SearchBar({ handleSearch, searchString }) {
  return (
    <div className="mb-4 flex justify-center ">
      <input
        onChange={handleSearch}
        className="my-2.5 h-9  w-1/4 rounded border-[3px] border-[#1462C6] text-xl outline-none"
        type="text"
        placeholder="Search for Stocks, ETFs, or Crypto..."
        autoComplete="off"
        value={searchString}
      ></input>
    </div>
  );
}

export default StockApp;
