import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { accessBilling, logoutUser } from "../app/userData";

export default function Landing({ setPaid }) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [membership, setMembership] = useState("");
  useEffect(() => {
    getEmail();
  }, []);

  async function getEmail() {
    try {
      const response = await fetch("/user/", {
        method: "GET",
        credentials: "include",
      });
      const parseRes = await response.json();
      setName(parseRes.user_name);
      setEmail(parseRes.user_email);
      setMembership(parseRes.membership_status);
    } catch (err) {
      console.error(err.message);
    }
  }

  return (
    <div className="flex min-h-screen flex-col items-center overflow-hidden bg-[#1462c6]">
      <Link to="/" className="mt-6 select-none text-white no-underline">
        <h2 className="mr-10 flex items-center justify-center">
          <img className="w-[88px]" src="https://www.powerinvest.pro/logo2.gif" alt="logo" />
          Power Invest Pro
        </h2>
      </Link>
      <h1 className=" mb-0 select-none text-3xl text-white">Welcome {name.toUpperCase()}</h1>
      {membership === "expired" ? (
        <div className="mt-4 rounded bg-white p-8">
          <h1 className=" select-none text-2xl text-black">Your Subscription has expired</h1>
          <p className="select-none text-2xl text-black ">
            Please go here to renew ➜{" "}
            <button
              className="cursor-pointer rounded border-2 border-[#1462c6]  bg-white px-4 text-xl font-bold text-[#1462c6] hover:bg-[#fec458]"
              onClick={() => accessBilling()}
            >
              Manage billing
            </button>
          </p>
          <button
            className="cursor-pointer rounded border-2  border-black bg-white px-4 text-base font-bold hover:bg-gray-200"
            onClick={() => {
              logoutUser();
            }}
          >
            Log Out
          </button>
        </div>
      ) : (
        <div>
          <h2 className=" mb-0 select-none text-xl text-white">
            {" "}
            Account Status: {membership.toUpperCase()}
          </h2>
          <h1 className=" select-none text-white">Please Select a Plan</h1>
          <stripe-pricing-table
            pricing-table-id="prctbl_1MjhmFAe7wNAJzc4CSTmz7nW"
            publishable-key="pk_live_VYP7UwvVf6xGq4vlC4EYaTI300laniSujy"
            customer-email={email}
          />
          <div className=" text-right">
            <button
              className=" cursor-pointer  bg-inherit text-right text-white underline hover:text-yellow-500"
              onClick={() => logoutUser()}
            >
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
