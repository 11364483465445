import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart, Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Filler,
  Legend
);

export default function Graph({
  symbol,
  data = { historical: [], today: [], fiveDay: [] },
  range = "fiveYear",
}) {
  const segments = {
    fiveYear: data.historical,
    oneYear: data.historical,
    sixMonths: data.historical,
    threeMonths: data.historical,
    oneMonth: data.historical,
    yearToDate: data.historical,
    today: data.today,
    fiveDay: data.fiveDay,
  };

  const dataSegment = segments[range];
  const chopData = {
    fiveYear: filterDatesLast5Years,
    oneYear: filterDatesLastYear,
    sixMonths: filterDatesSixMonths,
    yearToDate: filterDatesYearToDate,
    threeMonths: filterDatesThreeMonths,
    oneMonth: filterDatesOneMonth,
    fiveDay: (data) => {
      return data;
    },
    today: (data) => {
      data.pop();
      return data;
    },
  };

  const fiveYears = chopData[range](dataSegment).filter((day, index) => {
    switch (range) {
      case "fiveYear":
        return index % 7 === 0;
      case "oneYear":
        return index % 3 === 0;
      case "sixMonths":
        return index;
      case "threeMonths":
        return index;
      case "oneMonth":
        return index;
      default:
        return index;
    }
  });

  console.log(fiveYears);

  fiveYears.push(dataSegment[dataSegment.length - 1]);
  const filterData = fiveYears[0] ? fiveYears.map((day) => day.close) : [];

  const labels = fiveYears[0]
    ? fiveYears.map((day, i) => `${day.month}/${day.day}/${day.year}`)
    : [];

  //Function for adding points to graph - lots of possible features from this
  const fourPoints = (array) => {
    const firstIndex = 0;
    const lastIndex = array.length - 1;
    const twentyFivePercentIndex = Math.floor(array.length * 0.25);
    const fiftyPercentIndex = Math.floor(array.length * 0.5);
    const seventyFivePercentIndex = Math.floor(array.length * 0.75);
    // return [twentyFivePercentIndex, fiftyPercentIndex, seventyFivePercentIndex, lastIndex];
    return [lastIndex];
  };

  const quarterPoints = fourPoints(filterData);

  const data2 = {
    labels: range === "today" ? labels.concat(new Array(391 - labels.length)) : labels, //for incomplete trading day data
    datasets: [
      {
        fill: "origin",
        label: "",
        data: filterData,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        // tension: 0.1,
      },
    ],
  };

  const months = [
    "",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const xTicks = {
    fiveYear: 5,
    oneYear: 4,
    sixMonths: 4,
    threeMonths: 3,
    oneMonth: 4,
    yearToDate: 4,
    today: 4,
    fiveDay: 5,
  };

  const roundThirty = (num) => {
    if (num >= 30) return 30;
    return 0;
  };

  const roundHour = (hour) => {
    if (hour <= 12) return hour;
    return hour - 12;
  };

  function getTheDay({ day, month, year }) {
    const dayNum = new Date(year, month - 1, day).getDay();
    const daysOfWeek = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Saturday"];
    return daysOfWeek[dayNum];
  }

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    backgroundColor: "white",

    pointRadius: (context) => {
      const pointLength = context.dataset.data.length;
      const arr = Array(pointLength).fill(0);

      quarterPoints.forEach((index) => {
        arr[index] = 8;
      });
      // arr[pointLength - 1] = 8;
      return arr;
    },
    pointBackgroundColor: "blue",
    pointHoverRadius: 8,

    scales: {
      x: {
        ticks: {
          // padding:
          includeBounds: true,
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          maxTicksLimit: xTicks[range],
          // align: "end",
          callback: function (val, index) {
            if (!fiveYears[index]) return "";
            const { day, month, year, hour, minute } = fiveYears[index];
            switch (range) {
              case "fiveYear":
                return year + 1;
              case "oneYear":
                return months[month] + " " + year;
              case "sixMonths":
                return months[month] + " " + day;
              case "threeMonths":
                return months[month] + " " + day;
              case "oneMonth":
                return months[month] + " " + day;
              case "yearToDate":
                return months[month] + " " + year;
              case "today":
                if (!fiveYears[index]?.hour) return "";
                return (
                  roundHour(hour) +
                  ":" +
                  roundThirty(minute).toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  }) +
                  `${hour >= 12 ? "PM" : "AM"}`
                );

              case "fiveDay":
                return day ? month + "/" + day + ` (${getTheDay({ day, month, year })})` : "";
              default:
                return year;
            }
          },
          color: "red",
        },
      },
      y: {
        // suggestedMin: Math.min(...filterData) - 5,
        suggestedMin: Math.min(...filterData) * 0.99,
        // suggestedMax: Math.max(...filterData) * 1.01,
      },
    },
    color: "red",
    plugins: {
      datalabels: {
        display: function (context) {
          return quarterPoints.includes(context.dataIndex);
          // return context.dataIndex === context.dataset.data.length - 1;
        },
        align: "left",
        // anchor: "end",
        // paddingRight: 10,
        offset: 12,
        color: "white",
        backgroundColor: "blue",
        borderRadius: 5,
        font: { weight: "bold", size: 14 },
        pointRadius: 3,

        formatter: function (value, context) {
          return "$" + value;
        },
      },
      legend: {
        display: false,
        position: "bottom",
      },
      title: {
        display: false,
        text: `${symbol} 5 Year Price Chart`,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return <Line options={options} data={data2} />;
}

function filterDatesLast5Years(dateArray) {
  const currentDate = new Date(); // Get the current date
  const fiveYearsAgo = new Date(); // Create a new date object
  fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5); // Subtract 5 years from the current date
  return dateArray.filter((dateObj) => {
    const date = new Date(dateObj.year, dateObj.month - 1, dateObj.day); // Create a new date object from the dateObj properties
    return date >= fiveYearsAgo && date <= currentDate; // Check if the date falls within the last 5 years
  });
}

function filterDatesLastYear(dateArray) {
  const currentDate = new Date(); // Get the current date
  const oldDate = new Date(); // Create a new date object
  oldDate.setFullYear(oldDate.getFullYear() - 1); // Subtract 5 years from the current date

  return dateArray.filter((dateObj) => {
    const date = new Date(dateObj.year, dateObj.month - 1, dateObj.day); // Create a new date object from the dateObj properties
    return date >= oldDate && date <= currentDate; // Check if the date falls within the last 5 years
  });
}

function filterDatesSixMonths(dateArray) {
  const currentDate = new Date(); // Get the current date
  const oldDate = new Date(); // Create a new date object
  oldDate.setMonth(oldDate.getMonth() - 6); // Subtract 5 years from the current date

  return dateArray.filter((dateObj) => {
    const date = new Date(dateObj.year, dateObj.month - 1, dateObj.day); // Create a new date object from the dateObj properties
    return date >= oldDate && date <= currentDate; // Check if the date falls within the last 5 years
  });
}

function filterDatesThreeMonths(dateArray) {
  const currentDate = new Date(); // Get the current date
  const oldDate = new Date(); // Create a new date object
  oldDate.setMonth(oldDate.getMonth() - 3); // Subtract 5 years from the current date

  return dateArray.filter((dateObj) => {
    const date = new Date(dateObj.year, dateObj.month - 1, dateObj.day); // Create a new date object from the dateObj properties
    return date >= oldDate && date <= currentDate; // Check if the date falls within the last 5 years
  });
}

function filterDatesOneMonth(dateArray) {
  return dateArray.slice(dateArray.length - 23); //max of 23 trading days in a month, will need to change if data is not daily
}

function filterDatesYearToDate(dateArray) {
  const currentDate = new Date(); // Get the current date
  const oldDate = new Date(currentDate.getFullYear(), 0, 1); // Create a new date object

  return dateArray.filter((dateObj) => {
    const date = new Date(dateObj.year, dateObj.month - 1, dateObj.day); // Create a new date object from the dateObj properties
    return date >= oldDate && date <= currentDate; // Check if the date falls within the last 5 years
  });
}
