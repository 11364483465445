//Handles dynamic styles (index.css for static styles)
export function tabSelected(isSelected) {
  return isSelected ? { backgroundColor: "rgb(243, 194, 104)", fontSize: 16 } : null;
}

//Colors text based on value.
export function getColor(val, showHighlight) {
  const delta = Math.abs(val);
  if (showHighlight && delta >= 6) {
    return { color: "white" };
  }

  switch (true) {
    case val === 0:
      return { color: "grey" };
    case val < 0:
      return { color: "red" };
    case val > 0:
      return { color: "green" };
    default:
      return { color: "black" };
  }
}

//If cell is selected will highlight background color
export function getSelected(isSelected) {
  return isSelected ? { backgroundColor: "rgb(243, 194, 104)" } : null;
}

export function compactCell(isCompact) {
  return !isCompact ? { paddingTop: "10px", paddingBottom: "10px" } : { padding: "0px" };
}

//highlights stock row based on percentage change in price
export function toggleHighlight(val, highlight) {
  //red or green background depending on direction of % change
  // const newBg = val < 1 ? '#ff8f8f' : '#7def81';
  const newBg = val < 1 ? "#ce0000" : "#0da413";
  const newBg2 = val < 1 ? "red" : "#008000";

  const delta = Math.abs(val);

  const weights = {
    light: { fontWeight: 200, color: "black" },
    normal: { fontWeight: 500, color: "black" },
    bold: { fontWeight: 700, color: newBg2 },
    highlighted: { fontWeight: 900, backgroundColor: newBg, color: "white" },
  };

  //highlights row based on % delta
  if (highlight) {
    switch (true) {
      case delta === 0:
        return weights.light;
      case delta < 4:
        return weights.normal;
      case delta < 6:
        return weights.bold;
      case delta >= 6:
        return weights.highlighted;
      default:
        break;
    }
  }
}

export function boldTarget(isBold) {
  return isBold ? { fontWeight: 700, color: "#f4511e" } : { fontWeight: 400 };
}
