import { useState } from "react";
import { Link } from "react-router-dom";

import { Logo, Filter, Data, Sort } from "../app/icons";

//TODO - create a value section + video showcasing what the software can do.
export default function Landing() {
  return (
    <div className="min-h-screen w-screen select-none bg-white text-black">
      <NavBar />
      <Hero />
      <Features />
      <Faq />
      {/* <PriceTable /> */}
      <CallToAction />
      <Footer />
    </div>
  );
}

function NavBar() {
  function LoginButton() {
    const arrow1 = "https://www.powerinvest.pro/right1.svg";
    const arrow2 = "https://www.powerinvest.pro/right2.svg";

    const [icon, setIcon] = useState(arrow1);

    return (
      <Link
        onMouseEnter={() => setIcon(arrow2)}
        onMouseLeave={() => setIcon(arrow1)}
        to="/login"
        className="mr-5 inline-flex cursor-pointer items-center rounded-lg border-[3px] border-[#1462c6] px-4 py-2 pl-4 pr-2 text-xl font-bold text-[#1462c6] no-underline"
      >
        Log In
        <img src={icon} alt="login" className=" top-5 w-[30px]" />
      </Link>
    );
  }

  return (
    <div className="flex justify-center sm:px-8">
      <nav className="my-0 flex w-full select-none flex-col items-center justify-between gap-x-4 py-0 sm:w-11/12 sm:flex-row lg:w-3/4">
        <div className="hidden w-full cursor-default items-center justify-center whitespace-nowrap border-gray-500 py-2 text-2xl sm:flex sm:w-fit sm:border-0 ">
          <Logo size={window.innerWidth < 640 ? "small" : "base"} /> <b>Power Invest Pro</b>
        </div>
        <div className="hidden w-1/2 flex-col items-center justify-around gap-x-4 gap-y-2 whitespace-nowrap px-4 xl:flex xl:flex-row ">
          <a
            className="text-2xl font-bold text-black no-underline decoration-[3px] hover:underline"
            href="#features"
          >
            Features
          </a>
          {/* <a
            className="text-2xl font-bold text-black no-underline decoration-[3px] hover:underline"
            href="#pricing"
          >
            Pricing
          </a> */}

          <a
            className="text-2xl font-bold text-black no-underline decoration-[3px] hover:underline"
            href="#faqs"
          >
            Learn More{" "}
            {/* <img
          src="https://www.powerinvest.pro/banner.jpg"
          alt="banner"
          className="h-[29%] w-[29%] rounded-lg"
        /> */}
          </a>
          <a
            className="text-2xl font-bold text-black no-underline decoration-[3px] hover:underline"
            href="/register"
          >
            Create Account
          </a>
        </div>
        <div className=" flex w-full items-center justify-center gap-x-6  whitespace-nowrap border-y-2 border-gray-500 py-2 sm:mb-0 sm:w-fit sm:border-0 sm:py-0">
          <LoginButton />
          <RegisterButton text="Sign Up" />
        </div>
      </nav>
    </div>
  );
}

function Hero() {
  return (
    <section id="home">
      <div className="flex w-full flex-col items-center justify-center gap-x-10 bg-yellow-500 py-6 sm:flex-row sm:py-16 ">
        <div className="md:w-2/3 lg:w-1/3">
          <div className="my-auto mb-4 px-6 text-5xl sm:px-8 sm:text-6xl">
            Invest in Stocks, ETFs, and Crypto like never before.
          </div>
          <div className=" ">
            <p className="my-4 px-6 text-3xl sm:px-8">Research and track over 14,000 assets.</p>
            <div className=" inline-flex flex-wrap items-center justify-center gap-x-4 gap-y-4 px-2 sm:flex-nowrap sm:justify-start sm:px-8">
              <RegisterButton size="big" />
              <p className="bg-red mt-1 mb-0 mr-3 w-4/5 text-base font-semibold sm:w-2/5 sm:text-left">
                Beat the market and find your next trade before everyone else.
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <img
            src="https://www.powerinvest.pro/banner.jpg"
            alt="banner"
            className="hidden h-96 rounded-lg lg:block"
          />
        </div>
      </div>
    </section>
  );
}

function Features() {
  return (
    <section
      id="features"
      className="min-h-40 mx-auto flex w-11/12 flex-col justify-center  gap-y-4 gap-x-8 py-10  sm:flex-row lg:w-3/4"
    >
      <div className="px-4 text-xl sm:w-1/3">
        <div className="flex justify-center ">
          <Sort />
        </div>
        <div className="mx-auto mb-4 w-3/4   text-center font-bold sm:w-2/3">
          Sort and Track Every Asset
        </div>
        <div className="mx-auto w-4/5">
          <div className="border-t-2 border-gray-500 pl-4 pt-4">
            Sort the entire market into custom watchlists. Set Price Targets, and receive alerts
            when they're met.
          </div>
        </div>
      </div>

      <div className="px-4 text-xl sm:w-1/3">
        <div className="flex justify-center">
          <Data />
        </div>
        <div className="mx-auto mb-4 w-3/4   text-center font-bold sm:w-2/3">Lightning Speed</div>
        <div className="mx-auto w-4/5">
          <div className="border-t-2 border-gray-500 pl-4 pt-4">
            Don't let software be your bottleneck. Find an investment as soon as you think of it.
          </div>
        </div>
      </div>

      <div className="px-4 text-xl sm:w-1/3">
        <div className="flex justify-center">
          <Filter />
        </div>

        <div className="mx-auto mb-4 w-3/4   text-center font-bold sm:w-2/3">
          Only data that you need
        </div>
        <div className="mx-auto w-4/5">
          <div className="border-t-2 border-gray-500 pl-4 pt-4">
            Hide unwanted symbols and Exclude tracked ones from your future searches. Zero
            distracting ads or articles.
          </div>
        </div>
      </div>
    </section>
  );
}

function Faq() {
  const faq_list = [
    {
      question: "What does Power Invest Pro do?",
      answer:
        "Power invest Pro was created with the goal of on enabling any trader to analyze, follow, and sort the entire market however they like. Got 20 new stocks that meet your value screen? Instantly add them all to your Value List. Not a fan of mid-cap energy companies? Hide them forever, all at once. Bullish on Crypto? Put your favorite coins into their own list. Create buy and sell targets for everything. Customize the market based on what makes sense to YOU, the investor.",
    },
    {
      question: "Can I try this out for free first?",
      // answer: "Yes! We offer a free trial for your first 30 days and you can cancel at any time. ",
      answer:
        "Power Invest Pro is completely free! No credit card required. Just make an account and get started! ",
      link: true,
    },
    {
      question: "How is this different from other investment tools?",
      answer:
        "Most tools available to retail traders consist of out-dated finance websites that limit on how many items you can follow at once. They're full of ads, tracking cookies, and click-bait articles; that all slow you down. Unless you can afford a Bloomberg Terminal for $25,000 a year, you're at a big disadvantage.",
    },
    {
      question: "How is this software free?",
      answer:
        "The creator of Power Invest Pro is a full-time software engineer and trader. He created this tool to help other traders like himself. Until he is confident this is the best tool available for retail traders, it will remain free.",
    },
  ];

  const Question = ({ question, answer, link }) => {
    const Carrot = () => {
      return (
        <svg
          className="ml-1.5 mr-4 h-5 w-5 flex-shrink-0  transition duration-300 group-open:rotate-90"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            d="M19,9l-7,7L5,9"
            transform="matrix(0-1 1 0-.5 24.5)"
            fill="none"
            stroke="#000"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    };

    return (
      <details className="select-none[&_summary::-webkit-details-marker]:hidden group w-full ">
        <summary className="flex w-11/12 cursor-pointer items-center justify-between rounded-lg border-2 border-gray-500  bg-gray-50 py-3 px-4 hover:bg-gray-200 lg:px-8">
          <h2 className="ml-0 text-2xl font-medium text-gray-900 sm:ml-12">{question}</h2>
          <Carrot />
        </summary>
        <p className="mb-0 w-11/12 select-none rounded bg-white px-4 py-6 text-xl font-normal leading-relaxed lg:px-8 ">
          {answer}
          {link && (
            <>
              <Link to="/register" className="font-bold text-black hover:text-gray-500">
                Click Here
              </Link>{" "}
              to give it a try.
            </>
          )}
        </p>
      </details>
    );
  };

  return (
    <section id="faqs" className="bg-[#1462c6] bg-opacity-90 pt-6 ">
      <h3 className="text-center text-3xl font-semibold text-white">Want to know more?</h3>
      <div className="my-0 flex justify-center py-0">
        <div className="mb-10 flex w-11/12 flex-col justify-center space-y-5 md:w-3/5 lg:w-2/5 xl:w-1/3">
          {faq_list.map((faq) => (
            <Question {...faq} />
          ))}
        </div>
      </div>
    </section>
  );
}

function CallToAction() {
  return (
    <section id="call">
      <div className="flex  flex-col items-center justify-center bg-yellow-500">
        <div className="mt-20 mb-10 w-11/12  text-center text-4xl sm:w-1/2 sm:text-6xl lg:w-1/3">
          Customize your research and Invest like a pro.
        </div>

        <div className="mb-8">
          <RegisterButton size="large" />
        </div>
      </div>
    </section>
  );
}

// function PriceTable() {
//   const Prices = ({ plan }) => {
//     const price_list = {
//       trial: { price: "$0.00", original: "$0.00", sale: false },
//       monthly: { price: "$9.99", original: "$19.99", sale: true },
//       yearly: { price: "$99.99", original: "$199.99", sale: true },
//     };

//     return (
//       <>
//         {price_list[plan].sale && (
//           <span className=" mr-2 font-light line-through decoration-orange-500 decoration-2">
//             {price_list[plan].original}
//           </span>
//         )}
//         <div className="mr-1 inline-block"> {price_list[plan].price}</div>
//         {price_list[plan].sale && (
//           <div className=" ml-2 inline-block rotate-3 transform  text-lg font-bold text-orange-500">
//             -50% off!
//           </div>
//         )}
//       </>
//     );
//   };

//   const Features = ({ plan }) => {
//     const feature_list = {
//       trial: [
//         "Live Data from over 14,000 assets.",
//         "Follow and create price targets for up to 50 securities",
//         "Create up to 5 custom user Watchlists",
//       ],
//       monthly: [
//         "Pay as you go. Cancel any time.",
//         "Live Data from over 14,000 assets.",
//         "Follow and create price targets for All 14,000+ assets",
//         "Maximum of 30 custom Watchlists!",
//       ],
//       yearly: [
//         "Yearly Plan - 2 months free!",
//         "Live Data from over 14,000 assets.",
//         "Follow and create price targets and notes for All 14,000+ assets",
//         "Maximum of 30 custom Watchlists!",
//       ],
//     };

//     return (
//       <div className="mt-4 mb-4  text-sm font-normal">
//         Plan Includes:
//         {feature_list[plan].map((feature) => (
//           <div className="flex items-center">
//             <img src="https://www.powerinvest.pro/check.svg" alt="check" className=" w-5" />{" "}
//             <span className="mt-2 ml-2">{feature}</span>
//           </div>
//         ))}
//       </div>
//     );
//   };

//   return (
//     <section id="pricing" className=" ">
//       <div className="m-0 py-5 text-center text-[40px] font-bold ">Choose a Plan</div>
//       {/* table  */}
//       <div className="flex justify-center ">
//         <div className="mb-10 mt-4 flex min-h-[450px] w-2/5  rounded-lg bg-white ">
//           {/* trial  */}
//           <div className=" -mb-4 w-1/3 rounded-b-lg border-[3px] border-orange-500 bg-white hover:bg-gray-50">
//             <div className=" -mx-[2px] -mt-[20px] rounded-lg rounded-b-none  bg-orange-500 px-2 text-center text-base font-bold text-white">
//               Get Started Now
//             </div>
//             <div className="pl-5 pr-4 pt-4">
//               <div className=" text-3xl font-semibold">Free Trial</div>
//               <div className="text-gray-600">As low as</div>
//               <div className="text-2xl font-bold">
//                 <Prices plan={"trial"} />
//                 <div className="mt-4 mb-3 text-base font-normal">
//                   30 day free trial. Cancel at any time free of charge.
//                 </div>
//                 <div className="py-2">
//                   <RegisterButton text={"Start Trial"} />
//                 </div>
//                 <Features plan={"trial"} />
//               </div>
//             </div>
//           </div>

//           {/* monthly  */}
//           <div className=" solid  s  w-1/3 border-2 border-r-0 border-l-0 border-gray-500 pt-4 pl-6 pr-4 hover:bg-gray-100">
//             <div className="text-3xl font-semibold">Monthly</div>
//             <div className="text-gray-600">As low as</div>
//             <div className="text-2xl font-bold">
//               <Prices plan={"monthly"} />
//               <div className="mt-4 mb-2 text-base font-normal">
//                 Pay as you go. Access to all premium features.
//               </div>
//               <div className="py-2">
//                 <RegisterButton text={"Get Monthly"} />
//               </div>
//               <Features plan={"monthly"} />
//             </div>
//           </div>

//           {/* yearly  */}
//           <div className=" w-1/3   rounded-r-lg border-2 border-gray-500 pt-4 pr-4 pl-6 hover:bg-gray-100">
//             <div className=" text-3xl font-semibold">Yearly </div>
//             <div className="text-gray-600">As low as</div>
//             <div className="text-2xl font-bold">
//               <Prices plan={"yearly"} />
//               <div className="mt-4 mb-2 mr-1 text-base font-normal">
//                 The Best Deal, Pay for the year and get two months free!
//               </div>
//               <div className="py-3">
//                 <RegisterButton text={"Get Yearly"} />
//               </div>
//               <Features plan={"yearly"} />
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

function Footer() {
  //ToDO make email work
  return (
    <div className="my-6 flex select-text flex-col items-center justify-center">
      <div>Power Invest Pro - 2023</div>
      <div className="mt-2">
        Questions?{" "}
        <a href="mailto: help@powerinvest.pro" className=" text-black">
          help@powerinvest.pro
        </a>
      </div>
    </div>
  );
}

const RegisterButton = ({ text = "Try for Free", size = "base" }) => {
  const arrow1w = "https://www.powerinvest.pro/right1w.svg";
  const arrow2w = "https://www.powerinvest.pro/right2w.svg";
  const [icon, setIcon] = useState(arrow1w);
  return (
    <Link
      onMouseEnter={() => setIcon(arrow2w)}
      onMouseLeave={() => setIcon(arrow1w)}
      style={
        size === "base"
          ? { paddingTop: "0.5rem", paddingBottom: "0.5rem" }
          : {
              paddingTop: "1rem",
              paddingBottom: "1rem",
              paddingRight: "1.5rem",
              paddingLeft: "1.5rem",
              textAlign: "center",
              fontSize: "1.5rem",
              lineHeight: "2rem",
              columnGap: "1rem",
            }
      }
      className="my-auto flex max-h-24 cursor-pointer items-center justify-center whitespace-nowrap rounded-lg border-[3px] border-[#1462c6] bg-[#1462c6]  pl-4 pr-2 text-xl  font-bold text-white no-underline hover:bg-[#1462c6] hover:text-white"
      to="/register"
    >
      {text}
      <img
        src={icon}
        alt="arrow"
        className="top-5"
        style={size === "base" ? { width: 30 } : { width: 45 }}
      />
    </Link>
  );
};
