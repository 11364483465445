export function HideIcon() {
  return (
    <img
      style={{ width: 18, marginBottom: -4 }}
      src="https://www.powerinvest.pro/hide.svg"
      alt="hide icon"
    />
  );
}

export function UnhideIcon() {
  return (
    <img
      style={{ width: 18, marginBottom: -4 }}
      src="https://www.powerinvest.pro/unhide.svg"
      alt="hide icon"
    />
  );
}

export function PlusIcon() {
  return (
    <img
      style={{ width: 15, marginBottom: -3 }}
      src="https://www.powerinvest.pro/plus.svg"
      alt="add new tab"
    />
  );
}

export function AlertIcon() {
  return (
    <img
      style={{ width: 18, marginBottom: -3, marginRight: 5 }}
      src="https://www.powerinvest.pro/alert.svg"
      alt="alert"
    />
  );
}

export function CloseIcon() {
  return (
    <img
      className=" rounded hover:bg-gray-800"
      style={{ width: 28, float: "right", cursor: "pointer" }}
      src="https://www.powerinvest.pro/close.svg"
      alt="close icon"
    />
  );
}

export function GearIcon({ size = 20 }) {
  return (
    <img
      style={{ width: size, marginBottom: -4 }}
      src="https://www.powerinvest.pro/gear.svg"
      alt="gear"
    />
  );
}

export function Add({ size = 16 }) {
  return (
    <img
      style={{ width: size, marginBottom: -3, marginRight: 3 }}
      src="https://www.powerinvest.pro/add2.svg"
      alt="add"
    />
  );
}

export function Remove({ size = 18 }) {
  return (
    <img
      style={{ width: size, marginBottom: -5, marginRight: 3, marginLeft: -3 }}
      src="https://www.powerinvest.pro/remove.svg"
      alt="remove"
    />
  );
}

export function Check({ size = 16 }) {
  return (
    <img
      style={{ width: size, marginBottom: -2 }}
      src="https://www.powerinvest.pro/check.svg"
      alt="check"
    />
  );
}

export function LogoutIcon({ size = 18 }) {
  return (
    <img
      style={{ width: size, marginBottom: -4 }}
      src="https://www.powerinvest.pro/logout.svg"
      alt="logout"
    />
  );
}

export function MembershipIcon({ size = 24 }) {
  return (
    <img
      style={{ width: size, marginBottom: -8 }}
      src="https://www.powerinvest.pro/membership.svg"
      alt="membership settings"
    />
  );
}

export function BellIcon({ alertCount, size = 26 }) {
  return alertCount > 0 ? (
    <img
      style={{ width: size, marginBottom: -5 }}
      src="https://www.powerinvest.pro/alert.svg"
      alt="alert"
    />
  ) : (
    <img
      style={{ width: size, marginBottom: -5 }}
      src="https://www.powerinvest.pro/offalert.svg"
      alt="no alerts"
    />
  );
}

export function AlertRowIcon() {
  return (
    <img
      style={{ width: 20, marginBottom: -5, marginRight: 4 }}
      src="https://www.powerinvest.pro/alert.svg"
      alt="alert"
    />
  );
}

export function AddIcon() {
  return (
    <img
      className="w-5 rounded-[50%] hover:bg-slate-800"
      src="https://www.powerinvest.pro/add.svg"
      alt="add"
    />
  );
}

export function ClearIcon() {
  return (
    <img
      className="w-5 rounded-[50%] hover:bg-slate-800"
      src="https://www.powerinvest.pro/clear.svg"
      alt="clear"
    />
  );
}

export function NoteIcon() {
  return (
    <img
      className="rounded-[50%] hover:bg-slate-800"
      style={{ width: 18, marginBottom: -5, marginRight: 2 }}
      src="https://www.powerinvest.pro/note.svg"
      alt="note"
    />
  );
}

//Animated GIFs
export function Logo({ size = "base" }) {
  const sizes = {
    base: { width: 100, height: 100 },
    small: { width: 66, height: 66 },
  };
  return (
    <img
      style={{ width: sizes[size].width, height: sizes[size].height }}
      className="-my-2"
      src="https://www.powerinvest.pro/logo2.gif"
      alt="Power Invest Pro Logo"
    />
  );
}

export function Filter() {
  return (
    <img
      className="-m-4 mb-0 h-[110px] w-[110px]"
      src="https://www.powerinvest.pro/filter.gif"
      alt="Power Invest Pro Logo"
    />
  );
}

export function Data() {
  return (
    <img
      className="-m-4 mb-0 h-[110px] w-[110px]"
      src="https://www.powerinvest.pro/data.gif"
      alt="Power Invest Pro Logo"
    />
  );
}

export function Sort() {
  return (
    <img
      className="-m-4 mb-0 h-[110px] w-[110px]"
      src="https://www.powerinvest.pro/list.gif"
      alt="Power Invest Pro Logo"
    />
  );
}
