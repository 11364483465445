export async function updateUserWatchlists(newLists) {
  //formats watchlist
  const body = { watchlist_data: newLists };

  try {
    const response = await fetch("/user/watchlists", {
      method: "PUT",
      credentials: "include",
      headers: {
        // token: sessionStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (err) {
    console.error(err.message);
  }
}

export async function updateUserTargets(newTargets) {
  const body = { target_data: newTargets };

  try {
    const response = await fetch("/user/targets", {
      method: "PUT",
      credentials: "include",
      headers: {
        // token: sessionStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (err) {
    console.error(err.message);
  }
}

export async function updateNote(symbol, note_data) {
  const body = { note_data, symbol };
  try {
    const response = await fetch(`/user/notes`, {
      method: "PUT",
      credentials: "include",
      headers: {
        // token: sessionStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (err) {
    console.error(err.message);
  }
}

export async function deleteNote(symbol) {
  const body = { symbol };
  try {
    const response = await fetch(`/user/notes`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        // token: sessionStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (err) {
    console.error(err.message);
  }
}

export async function accessBilling() {
  try {
    const response = await fetch("/payments/customer-portal", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const parseRes = await response.json();
    window.location.href = parseRes;
  } catch (err) {
    console.error(err.message);
  }
}

export async function logoutUser() {
  try {
    const response = await fetch("/auth/logout", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (err) {
    console.error(err.message);
  }
  window.location.href = "/login";
}
