import { Link } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";

function Register({ setAuth }) {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    name: "",
  });
  const [passwordError, setPasswordError] = useState("");

  const { email, password, name } = inputs;

  const onChange = (e) => setInputs({ ...inputs, [e.target.name]: e.target.value });

  async function tryRegister(e) {
    e.preventDefault();
    try {
      if (password.length < 7) return setPasswordError("Password must be at least 7 characters.");
      if (!/\d/.test(password))
        return setPasswordError("Password must contain at least one digit.");

      const response = await fetch("/auth/register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ email, password, name }),
      });
      const parseRes = await response.json();
      if (parseRes === true) {
        // sessionStorage.token = parseRes.token;
        toast.success("Account Created.");
        // setAccountCreated(true);
        setAuth(true);
      } else {
        toast.error(parseRes);
        setAuth(false);
      }
    } catch (err) {
      setAuth(false);
      console.error(err);
    }
  }

  return (
    <div className="flex min-h-screen flex-col items-center overflow-hidden bg-[#1462c6]">
      <Link to="/" className="mt-6 select-none text-white no-underline">
        <h2 className="mr-10 flex items-center justify-center">
          <img className="w-[88px]" src="https://www.powerinvest.pro/logo2.gif" alt="logo" />
          Power Invest Pro
        </h2>
      </Link>

      <form
        className="mt-8 flex w-1/4 min-w-[280px] flex-col rounded-lg border-2 border-white bg-white p-16 font-sans"
        onSubmit={tryRegister}
      >
        <div className=" font-serif mx-auto pb-5 text-3xl font-semibold text-gray-700 ">
          Create your free account
        </div>
        <label className="mb-2 ml-1 text-left text-lg">Username</label>
        <input
          className="mb-5 w-full rounded border-2 border-gray-400  p-2  text-base outline-none focus:shadow-[0_0_0_2px] focus:shadow-sky-300 "
          type="text"
          name="name"
          placeholder="Username"
          value={name}
          onChange={(e) => onChange(e)}
        />
        <label className="mb-2 ml-1 text-left text-lg">Email</label>
        <input
          className="mb-5 w-full rounded border-2 border-gray-400  p-2  text-base outline-none focus:shadow-[0_0_0_2px] focus:shadow-sky-300 "
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e) => onChange(e)}
        />
        <label className="mb-2 ml-1 text-left text-lg">Password</label>
        <input
          className="mb-5 w-full rounded border-2 border-gray-400  p-2  text-base outline-none focus:shadow-[0_0_0_2px] focus:shadow-sky-300 "
          type="password"
          name="password"
          placeholder="Password"
          autocomplete="new-password"
          id="new-password"
          value={password}
          onChange={(e) => onChange(e)}
        />
        {passwordError && <div style={{ color: "red", marginBottom: 20 }}>{passwordError}</div>}
        <button className="font-white mx-auto mt-2 w-4/5  cursor-pointer rounded border-0 bg-[#eab308] py-2 text-base hover:bg-[#f7d360]">
          Create Account
        </button>
      </form>

      <p className="text-white">
        Already a Member?{" "}
        <Link className="font-bold text-[#eab308] hover:text-[#f7d360]" to="/login">
          Sign In
        </Link>
      </p>
    </div>
  );
}

export default Register;
