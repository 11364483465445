import StockApp from "./app/StockApp";
import Login from "./auth/Login";
import Register from "./auth/Register";
import Landing from "./Landing/Landing";
import Billing from "./auth/Billing";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import dotenv from 'dotenv';

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isPaid, setIsPaid] = useState(true);

  useEffect(() => {
    checkAuth();
  }, []);

  function setAuth(boolean) {
    setIsAuthenticated(boolean);
  }

  function setPaid(boolean) {
    setIsPaid(boolean);
  }

  async function checkAuth() {
    try {
      const response = await fetch("/auth/verify", {
        method: "GET",
        credentials: "include",
      });
      const parseRes = await response.json();
      if (parseRes === true) {
        // toast.success("Logged in.", { toastId: "login", position: toast.POSITION.TOP_LEFT }); Toast would show every 90 seconds.
        // await checkMember();
        setIsPaid(true); //To remove paywall
        setIsAuthenticated(true);
      }
    } catch (err) {
      setIsAuthenticated(false);
      console.error(err);
    }
  }

  async function checkMember() {
    //Todo, can probably be handled on backend?
    try {
      const response = await fetch("/payments/membership", {
        method: "GET",
        credentials: "include",
      });
      const parseRes = await response.json();
      console.log(parseRes, "is member");
      if (parseRes === true) setIsPaid(true);
    } catch (error) {
      console.error(error);
    }
  }

  function checkMobile() {
    const screenWidth = window.innerWidth;
    const minWidth = 720;
    return screenWidth > minWidth;
  }

  //prettier-ignore
  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route exact path="/" element={isAuthenticated ? <Navigate to="/app" /> : <Landing setAuth={setAuth} />} />
        {/* <Route exact path="/app" element={isPaid ? checkMobile() ? <StockApp checkAuth={checkAuth} /> : <MobileWarn /> : <Navigate to="/billing" />} /> */}
        <Route exact path="/app" element={isAuthenticated ? checkMobile() ? <StockApp checkAuth={checkAuth} /> : <MobileWarn /> : <Navigate to="/login" />} />
        <Route exact path="/billing" element={isAuthenticated ? <Billing setPaid={setPaid} /> : isPaid ? <Navigate to="/app" /> : <Navigate to="/login" />} />
        <Route exact path="/login" element={!isAuthenticated ? <Login setAuth={setAuth} setPaid={setPaid} /> : <Navigate to="/app" />} />
        <Route exact path="/register" element={!isAuthenticated ? <Register setAuth={setAuth} /> : <Navigate to="/app" />} />
        <Route path="*" element={isAuthenticated ? <Navigate to="/app" /> : <Landing setAuth={setAuth} />} />
      </Routes>
    </Router>
  );
}

function MobileWarn() {
  return (
    <div className="min-h-screen bg-[#7285bb] p-4">
      <div className="text-md rounded bg-white p-3">
        This application is optimized for Desktop Computers and Tablets. Please log in using a
        larger device for the optimal experience.
      </div>
    </div>
  );
}
